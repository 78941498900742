import rootReducer from "../src/sessions/reducers";
import { initialState } from "../src/sessions/reducers/SessionReducer";
import {
  renderApp,
  configureStoreWithProps,
} from "../src/common/containers/Root";

import SessionApp from "../src/sessions/components/SessionApp";

import "coupa-common-js/styles/scss/coupabase.scss";
import "coupa-common-js/styles/scss/components/coupacomponents.scss";

document.addEventListener("DOMContentLoaded", () => {
  const targetSessionLoadingEl = document.querySelector(
    ".s-sessionLoadingContainer"
  );
  if (!targetSessionLoadingEl.classList.contains("hide")) {
    targetSessionLoadingEl.classList.add("hide");
  }

  const targetEl = document.querySelector(`[data-react-component="Session"]`);
  if (targetEl) {
    const props = JSON.parse(
      targetEl.getAttribute("data-react-component-props")
    );

    const store = configureStoreWithProps(rootReducer, {
      SessionReducer: { ...initialState(), ...props },
    });
    renderApp(SessionApp, targetEl, store);
  }
});
