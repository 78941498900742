import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

export function configureStore() {
  return applyMiddleware(thunkMiddleware)(createStore);
}

export function configureStoreWithProps(reducer, initialState) {
  return applyMiddleware(thunkMiddleware)(createStore)(reducer, initialState);
}

export function renderApp(Component, rootEl, store, props = {}) {
  ReactDOM.render(
    <Provider store={store}>
      <Component {...props} />
    </Provider>,
    rootEl
  );
}
