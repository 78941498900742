import Immutable from "immutable";
import { ActionTypes, PAGES } from "../utils/AppConstants";
import { combineObjs } from "../utils/SupplierVerificationAppUtils";

const initialState = Immutable.fromJS({
  modalOpen: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
        modalData: action.modalContextData,
      };
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
      };
    case ActionTypes.FETCH_STATUS_SUCCESS:
      return {
        ...state,
        supplierAppData: action.data,
      };
    case ActionTypes.FETCH_STATUS_ERROR:
      return {
        ...state,
        errors: action.error.message,
      };
    case ActionTypes.FETCH_SUPPLIER_INFORMATION_SUCCESS:
      return {
        ...state,
        contactInfoData: action.data,
      };
    case ActionTypes.FETCH_SUPPLIER_INFORMATION_ERROR:
      return {
        ...state,
        errors: { form: action.error.message },
      };
    case ActionTypes.SAVE_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data, {
          save_action_completed: true,
        }),
        canReload: true,
      };
    case ActionTypes.SAVE_CONTACT_INFO_ERROR:
      return {
        ...state,
        errors: { form: action.error.message },
      };
    case ActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };
    case ActionTypes.SAVE_DOCUMENT_INFO_SUCCESS:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data, {
          save_action_completed: true,
        }),
      };
    case ActionTypes.SAVE_DOCUMENT_INFO_ERROR:
      return {
        ...state,
        errors: { document_upload: action.error.message },
      };
    case ActionTypes.SAVE_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data, {
          save_action_completed: true,
        }),
        canReload: true,
      };
    case ActionTypes.SAVE_PAYMENT_INFO_ERROR:
      return {
        ...state,
        errors: { payment: action.error.message },
      };
    case ActionTypes.SAVE_PAYMENT_ERRORS_SUCCESS:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data),
      };
    case ActionTypes.CREATE_STRIPE_INTENT_SUCCESS:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data),
      };
    case ActionTypes.CREATE_STRIPE_INTENT_ERROR:
      return {
        ...state,
        errors: { payment: action.error.message },
      };
    case ActionTypes.APPLY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data),
      };
    case ActionTypes.APPLY_PROMO_CODE_ERROR:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data),
      };
    case ActionTypes.REMOVE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], action.data, {
          hundred_percent_promo_applied: null,
        }),
      };
    case ActionTypes.REMOVE_PROMO_CODE_ERROR:
      return {
        ...state,
        errors: { payment: action.error.message },
      };
    case ActionTypes.CLEAR_PAYMENT_INTENT:
      return {
        ...state,
        supplierAppData: combineObjs(state["supplierAppData"], {
          clientSecret: null,
          subscriptionId: null,
          subscriberId: null,
          supplierPayment: null,
          userLocale: null,
          subscriptionPriceUSD: null,
          couponApplied: null,
          offeringName: null,
          couponError: null,
          couponErrorMessage: null,
          amountOff: null,
          percentOff: null,
          discountedAmount: null,
          totalDue: null,
          subTotal: null,
        }),
      };
    default:
      return state;
  }
}
