import React, { useState, useEffect } from "react";
import { recaptchaUrl } from "../utils/AppConstants";
import classNames from "classnames";

const Recaptcha = (props) => {
  const [recaptchaRendered, setRecaptchaRendered] = useState(false);

  useEffect(() => {
    if (!window.grecaptcha) {
      window.grecaptcha = null;
      const script = document.createElement("script");
      script.src = recaptchaUrl;
      script.async = true;

      window.onloadCallback = loadRecaptcha;
      document.head.appendChild(script);
    } else if (!recaptchaRendered) {
      loadRecaptcha();
    }
  }, []);

  useEffect(() => {
    if (recaptchaRendered) grecaptcha?.reset();
  }, [props.failedTime]);

  useEffect(() => {
    if (props.recaptchaReset && recaptchaRendered) grecaptcha?.reset();
  }, [props.recaptchaReset]);

  const loadRecaptcha = async () => {
    grecaptcha.render(document.querySelector(".s-recaptchaContainer"), {
      sitekey: props.siteKey,
      callback: "onreCaptchaVerify",
    });
    setRecaptchaRendered(true);
  };

  window.onreCaptchaVerify = (value) => {
    if (props.onChange) props.onChange(value);
  };

  return (
    <div
      className={classNames(
        "form_element",
        "recaptchaContainer",
        "s-recaptchaContainer",
        {
          hide: props.hide,
        }
      )}
    ></div>
  );
};
export default Recaptcha;
