import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CommonUtils, Button, Link, WithTooltip, Icon } from "coupa-common-js";
import { ActionTypes } from "../../utils/constants";

const JoinRequestConfirmation = () => {
  const dispatch = useDispatch();
  const { supplierName, adminUsers } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });

  const translationScope = (key, attrs = null) => {
    return CommonUtils.i18n(`session.join_request_confirmation.${key}`, attrs);
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <div className="mainHeader">{translationScope("title")}</div>
        <div className="subHeader">
          {translationScope("request_sent", { supplier_name: supplierName })}
        </div>
      </div>
    );
  };

  const handleDone = () => {
    dispatch({
      type: ActionTypes.LOAD_LOGIN,
    });
  };

  const renderAdminUsers = () => {
    return (
      <ul className="adminContactsList s-adminContactsList">
        {adminUsers.map((adminUser, index) => {
          return (
            <li key={index} className="adminContact s-adminContact">
              <Icon className="spiritEmail s-spriteEmail" />
              <WithTooltip
                key={index}
                tooltipType="info"
                tooltipContent={`${translationScope("email_to")} ${
                  adminUser.email
                }`}
                className="customTooltip"
                place="bottom"
              >
                <Link
                  key={index}
                  href={`mailto:${adminUser.email}`}
                  target="_blank"
                >
                  {adminUser.full_name}
                </Link>
              </WithTooltip>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="joinRequestConfirmationContainer">
      <div className="row">
        <div className="col-md-2 hidden-sm"></div>
        <div className="col-md-8">
          <div className="content">
            {renderHeader()}
            <div className="reachOut">
              {!CommonUtils.isEmpty(adminUsers) && renderAdminUsers()}
            </div>
          </div>
          <form className="joinRequestConfirmationForm" onSubmit={handleDone}>
            <Button
              type="submit"
              buttonText={CommonUtils.i18n("defaults.done")}
              className="blue btn_margin actionButton s-next"
            />
          </form>
        </div>
        <div className="col-md-2 hidden-sm"></div>
      </div>
    </div>
  );
};

export default JoinRequestConfirmation;
