import { AxiosAPIUtils, CommonUtils } from "coupa-common-js";
import { ActionTypes, Routes, Tabs } from "../utils/constants";
import SupplierCommonUtils from "../../common/utils/SupplierCommonUtils";

function getJoinSuggestionsSuccess(response, signupObj, userInfo) {
  const suppliersList = (response && response.merge_candidates) || [];
  if (suppliersList.length === 0) {
    return getJoinSuggestionsEmptyResult();
  } else {
    return {
      type: ActionTypes.JOIN_SUGGESTIONS_SUCCESS,
      data: {
        signupObj,
        suppliersList,
        cachedAccountInfo: userInfo,
      },
    };
  }
}

function getJoinSuggestionsEmptyResult() {
  return {
    type: ActionTypes.JOIN_SUGGESTIONS_EMPTY,
    data: {
      message: CommonUtils.i18n("session.join_account.no_matching_accounts"),
    },
  };
}

function getJoinSuggestionsFailed(response) {
  const data = response?.responseJSON?.data || {};
  const message = SupplierCommonUtils.getErrorMessage(response);
  return {
    type: ActionTypes.JOIN_SUGGESTIONS_FAILED,
    data: {
      message,
      ...data,
    },
  };
}

function sendJoinRequestSuccess(response) {
  let supplierName = response?.data?.supplier_name || "";
  let adminUsers = response?.data?.admin_users || [];
  return {
    type: ActionTypes.SEND_JOIN_REQUEST_SUCCESS,
    data: { supplierName, adminUsers },
  };
}

function sendJoinRequestFailed(response) {
  const data = response?.responseJSON?.data || {};
  const errors = response?.responseJSON?.errors?.join("<br/>") || "";
  let message = SupplierCommonUtils.getErrorMessage(response);
  if (errors) message += "<br/>" + errors;
  return {
    type: ActionTypes.SEND_JOIN_REQUEST_FAILED,
    data: {
      message,
      ...data,
    },
  };
}

export function getJoinSuggestions(userInfo, signupObj) {
  return (dispatch) => {
    return AxiosAPIUtils.post(Routes.JOIN_SUGGESTIONS_API, {
      ...userInfo,
      otp: signupObj.otp,
      invite_code: signupObj.invite_code,
      email: signupObj?.user?.email,
      "g-recaptcha-response": signupObj["g-recaptcha-response"],
      visitor_id: signupObj.visitor_id,
    })
      .then((response) => {
        dispatch(getJoinSuggestionsSuccess(response, signupObj, userInfo));
      })
      .catch((response) => {
        dispatch(getJoinSuggestionsFailed(response));
      });
  };
}

export function sendJoinRequest(userInfo, signupObj) {
  return (dispatch) => {
    return AxiosAPIUtils.post(Routes.SEND_JOIN_REQUEST_API, {
      ...userInfo,
      first_name: signupObj?.contact?.first_name,
      last_name: signupObj?.contact?.last_name,
      email: signupObj?.user?.email,
      otp: signupObj.otp,
      invite_code: signupObj.invite_code,
      "g-recaptcha-response": signupObj["g-recaptcha-response"],
      visitor_id: signupObj.visitor_id,
    })
      .then((response) => {
        dispatch(sendJoinRequestSuccess(response));
      })
      .catch((response) => {
        dispatch(sendJoinRequestFailed(response));
      });
  };
}
