import { CommonUtils } from "coupa-common-js";
import Immutable from "immutable";
import { join } from "../../profile/utils/address";
export function isValidPassword(password) {
  return /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,40}$/.test(password);
}

export function isValidEmail(email) {
  return /^([^:@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(email);
}

export function isValidTaxId(tax_id) {
  return /^[0-9\-A-Za-z]+$/.test(tax_id);
}

export function isValidDuns(duns) {
  return /^\d{2}-\d{3}-\d{4}$|^\d{9}$/.test(duns);
}

export function isValidPhoneNumber(phone_number) {
  return /^[()0-9 +-]+$/.test(phone_number);
}

export function formatAddress(address) {
  if (!address) {
    return "";
  }
  return join(
    [
      address.street1,
      address.street2,
      address.city,
      address.state,
      join([address.postal_code, address.country?.name]),
    ],
    ", "
  );
}

export const roleOptions = Immutable.fromJS([
  {
    name: CommonUtils.i18n("session.signup.customer_success_manager"),
    id: 1,
  },
  {
    name: CommonUtils.i18n("session.signup.accountant"),
    id: 3,
  },
  {
    name: CommonUtils.i18n("session.signup.something_else"),
    id: 5,
  },
]);

export const somethingElseRole = (value) => {
  return value === roleOptions.getIn([2, "id"]);
};

const boolOrReducer = (result, value) => {
  return result || value;
};

const isEmptyReducer = (result, value) => {
  return result || !CommonUtils.isEmpty(value);
};

export const hasAtleastOneAttribute = (object, recursive = false) => {
  if (!object) {
    return false;
  }
  if (!recursive) {
    return Object.values(object).reduce(isEmptyReducer, false);
  } else {
    return Object.values(object)
      .map((value) => {
        if (value !== null && typeof value === "object") {
          return hasAtleastOneAttribute(value, true);
        } else {
          return !CommonUtils.isEmpty(value);
        }
      })
      .reduce(boolOrReducer, false);
  }
};

export const formatAdminUsers = (adminUsers) => {
  return adminUsers
    .map((adminUser) => {
      return `${adminUser.first_name} ${adminUser.last_name}`;
    })
    .join(", ");
};

export const validateAccountInfo = (accountInfo) => {
  let errorsList = {};
  if (accountInfo.business_address.country_id) {
    if (
      CommonUtils.isEmpty(accountInfo.business_address.city) &&
      CommonUtils.isEmpty(accountInfo.business_address.postal_code)
    ) {
      errorsList["business_address"] = {
        city: CommonUtils.i18n("session.join_account.errors.empty_city"),
        postal_code: CommonUtils.i18n(
          "session.join_account.errors.empty_postal_code"
        ),
      };
    }
  }
  if (accountInfo.duns && !isValidDuns(accountInfo.duns)) {
    errorsList["duns"] = CommonUtils.i18n(
      "session.join_account.errors.invalid_duns"
    );
  }
  if (accountInfo.tax_id && !isValidTaxId(accountInfo.tax_id)) {
    errorsList["tax_id"] = CommonUtils.i18n(
      "session.join_account.errors.invalid_tax_id"
    );
  }
  return errorsList;
};
