import { AxiosAPIUtils, CommonUtils } from "coupa-common-js";
import { ActionTypes, Routes, Tabs } from "../utils/constants";

export function loginActionSuccess(
  response,
  visitorId,
  showLoadingCB,
  hideLoadingCB
) {
  if (response.tab === Tabs.CSP_LOGIN) {
    return { type: ActionTypes.CSP_LOGIN, data: { tab: Tabs.CSP_LOGIN } };
  }

  if (response.tab === Tabs.IDENTITY_LOGIN) {
    if (typeof showLoadingCB === "function") {
      showLoadingCB();
    }

    const identityLoginIframe = document.getElementById("identityLoginIframe");

    identityLoginIframe.onload = function(e) {
      let loaderHidden = false;

      iFrameResize(
        {
          checkOrigin: false,
          onResized: () => {
            if (typeof hideLoadingCB === "function") {
              if (!loaderHidden) {
                hideLoadingCB();
                loaderHidden = true;
                identityLoginIframe.removeAttribute("hidden");
              }
            }
          },
        },
        "#identityLoginIframe"
      );
    };

    identityLoginIframe.src = response.redirectTo;

    // Attach the message event listener
    window.addEventListener("message", receiveMessage, false);

    // Function to handle messages from the dynamically loaded iframe
    function receiveMessage(event) {
      // Check if the message is coming from the trusted iframe identityLoginIframe
      if (
        event.origin !== window.location.origin ||
        event.source !== identityLoginIframe.contentWindow
      ) {
        // Do not trust messages from other origins or sources
        return;
      }

      window.location.href = `${event.data.iframe_callback_url}&visitor_id=${visitorId}`;
    }

    return {
      type: ActionTypes.IDENTITY_LOGIN,
      data: { tab: Tabs.IDENTITY_LOGIN, ...response },
    };
  }

  return { type: ActionTypes.LOGIN_SUCCESS, data: { ...response } };
}

export function loginActionFailed(response) {
  const data = (response && response.data) || {};
  const recaptchaFailed = data.recaptchaFailed ? Date.now() : "";

  const message =
    (response && response.message) ||
    CommonUtils.i18n("session.login.login_failed");
  return {
    type: ActionTypes.LOGIN_FAILED,
    data: {
      message,
      recaptchaFailed,
      recaptchaSiteKey: data.recaptchaSiteKey,
    },
  };
}

export function login(loginObj, showLoadingCB, hideLoadingCB) {
  return (dispatch) => {
    return AxiosAPIUtils.post(Routes.LOGIN_API, loginObj)
      .then((response) => {
        dispatch(
          loginActionSuccess(
            response,
            loginObj.visitor_id,
            showLoadingCB,
            hideLoadingCB
          )
        );
      })
      .catch((response) => {
        if (typeof hideLoadingCB === "function") {
          hideLoadingCB();
        }
        dispatch(loginActionFailed(response.responseJSON));
      });
  };
}
