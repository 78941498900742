import {
  ActionTypes,
  Tabs,
  Routes,
  ActionTypesVerification,
} from "../utils/constants";

export function initialState() {
  return {
    tab: Tabs.LANDING,
    offering: "",
    isLoggedIn: false,
    redirectTo: Routes.HOME_PATH,
    identityLogin: false,
    recaptchaFailed: false,
    hasError: false,
    message: "",
    invite_email: "",
    supplier_name: "",
    invite_code: "",
    campaign: null,
    customer: null,
    can_edit_business_name: true,
    skipSignup: false,
    forwardMessage: "",
    enableForwardInvite: "",
    visitorId: "",
    countries: [],
    taxErrors: [],
  };
}

function resetRecaptcha(recaptchaFailed) {
  return recaptchaFailed ? Date.now() : "";
}

export default function(state = initialState(), action) {
  const recaptchaFailed = resetRecaptcha(action?.data?.recaptchaFailed);
  switch (action.type) {
    case ActionTypes.SET_VISITOR_ID:
      let { visitorId } = action.data;
      return { ...state, visitorId: visitorId };
    case ActionTypes.LOAD_SIGNUP:
      return { ...state, tab: Tabs.SIGNUP };
    case ActionTypes.LOAD_LOGIN:
      return { ...initialState(), tab: Tabs.LOGIN };
    case ActionTypes.LOAD_SIGNUP_WITHOUT_RESET:
      return { ...state, tab: Tabs.SIGNUP };
    case ActionTypes.LOAD_FORWARD_INVITATION:
      return { ...state, tab: Tabs.FORWARD_INVITATION };
    case ActionTypes.LOAD_VERIFIED:
      return { ...initialState(), tab: Tabs.VERIFIED };
    case ActionTypes.LOAD_JOIN_SUGGESTION:
      return { ...state, tab: Tabs.JOIN_ACCOUNT };
    case ActionTypes.RESET_MESSAGE:
      return {
        ...state,
        hasError: false,
        message: "",
      };
    case ActionTypes.SEND_OTP_FAILED: {
      let { message, recaptchaSiteKey, taxErrors } = action.data;
      return {
        ...state,
        hasError: true,
        tab: Tabs.SIGNUP,
        message,
        recaptchaFailed,
        recaptchaSiteKey,
        redirectTo: "",
        taxErrors,
      };
    }
    case ActionTypes.RESEND_OTP_FAILED: {
      let { message, recaptchaSiteKey } = action.data;
      return {
        ...state,
        hasError: true,
        tab: Tabs.OTP_VERIFICATION,
        message,
        recaptchaFailed,
        recaptchaSiteKey,
        redirectTo: "",
      };
    }
    case ActionTypes.SEND_OTP_SUCCESS: {
      let { message, signupObj } = action.data;
      return {
        ...state,
        message,
        tab: Tabs.OTP_VERIFICATION,
        signupObj,
        recaptchaFailed: false,
      };
    }
    case ActionTypes.LOGIN_SUCCESS: {
      let { redirectTo } = action.data;
      // TODO: [IDENTITY] will be addressed by https://coupadev.atlassian.net/browse/CD-369366
      //       - render Identity login page in iframe
      return {
        ...state,
        isLoggedIn: true,
        redirectTo,
      };
    }
    case ActionTypes.LOGIN_FAILED: {
      let { recaptchaSiteKey, message } = action.data;
      // TODO: [IDENTITY] will be addressed by https://coupadev.atlassian.net/browse/CD-369366
      //       - gracefully handle errors from Identity
      return {
        ...state,
        hasError: true,
        isLoggedIn: false,
        recaptchaFailed,
        message,
        recaptchaSiteKey,
        redirectTo: "",
      };
    }
    case ActionTypes.CSP_LOGIN: {
      return {
        ...state,
        tab: Tabs.CSP_LOGIN,
        isLoggedIn: false,
      };
    }
    case ActionTypes.IDENTITY_LOGIN: {
      let identityLogin = true;
      return {
        ...state,
        identityLogin,
        isLoggedIn: false,
      };
    }
    case ActionTypes.CREATE_ACCOUNT_FAILED: {
      let { recaptchaSiteKey, message, tab } = action.data;
      return {
        ...state,
        hasError: true,
        recaptchaFailed,
        message,
        tab,
        recaptchaSiteKey,
        redirectTo: "",
      };
    }
    case ActionTypes.CREATE_ACCOUNT_SUCCESS: {
      let { message, redirectTo } = action.data;
      return {
        ...state,
        isLoggedIn: true,
        redirectTo: redirectTo || Routes.HOME_PATH,
        message,
      };
    }
    case ActionTypes.FORWARD_INVITATION_FAILED: {
      let { message } = action.data;
      return {
        ...state,
        hasError: true,
        tab: Tabs.FORWARD_INVITATION,
        message,
        recaptchaFailed,
      };
    }
    case ActionTypes.FORWARD_INVITATION_SUCCESS: {
      let { message, forwardInvitationObject } = action.data;
      return {
        ...state,
        message,
        tab: Tabs.FORWARD_INVITATION,
        forwardInvitationObject,
        recaptchaFailed: false,
      };
    }
    case ActionTypes.VERIFIED_USER_FAILED: {
      let { recaptchaSiteKey, message } = action.data;
      return {
        ...state,
        hasError: true,
        recaptchaFailed,
        message,
        recaptchaSiteKey,
        redirectTo: "",
      };
    }
    case ActionTypes.VERIFIED_USER_SUCCESS: {
      return {
        ...state,
        ...action.data,
        tab: Tabs.PAYMENT,
        recaptchaFailed: false,
      };
    }
    case ActionTypes.SUGGEST_JOIN_ACCOUNT: {
      let { domain, signupObj, countryList } = action.data;
      return {
        ...state,
        signupObj,
        countryList,
        tab: Tabs.JOIN_ACCOUNT,
        domain: domain,
        recaptchaFailed: false,
      };
    }
    case ActionTypes.JOIN_SUGGESTIONS_SUCCESS: {
      let { signupObj, suppliersList, cachedAccountInfo } = action.data;
      return {
        ...state,
        signupObj,
        suppliersList,
        cachedAccountInfo,
        tab: Tabs.JOIN_SUGGESTIONS,
        recaptchaFailed: false,
      };
    }
    case ActionTypes.JOIN_SUGGESTIONS_EMPTY:
    case ActionTypes.JOIN_SUGGESTIONS_FAILED: {
      let { message, recaptchaSiteKey } = action.data;
      return {
        ...state,
        hasError: true,
        tab: Tabs.JOIN_ACCOUNT,
        message,
        recaptchaFailed,
        recaptchaSiteKey,
      };
    }
    case ActionTypes.SEND_JOIN_REQUEST_SUCCESS: {
      let { supplierName, adminUsers } = action.data;
      return {
        ...state,
        tab: Tabs.JOIN_CONFIRMATION,
        supplierName,
        adminUsers,
      };
    }
    case ActionTypes.SEND_JOIN_REQUEST_FAILED: {
      let { message, recaptchaSiteKey } = action.data;
      return {
        ...state,
        hasError: true,
        tab: Tabs.JOIN_SUGGESTIONS,
        message,
        recaptchaFailed,
        recaptchaSiteKey,
      };
    }
    case ActionTypesVerification.REDIRECT_SIGNUP: {
      return { ...initialState(), redirectTo: Routes.SIGNUP_PATH };
    }
    default:
      return state;
  }
}
