import { AxiosAPIUtils, CommonUtils } from "coupa-common-js";
import { ActionTypes, Routes, Tabs } from "../utils/constants";

function createVerfiedUserSuccess(response, verfiedUserObject) {
  return {
    type: ActionTypes.VERIFIED_USER_SUCCESS,
    data: {
      ...response,
      invite_email: verfiedUserObject.email,
      first_name: verfiedUserObject.user_details.first_name,
      last_name: verfiedUserObject.user_details.last_name,
      business_name: verfiedUserObject.user_details.business_name,
    },
  };
}

export function createVerfiedUserFailed(response) {
  const data = response?.data || {};
  const recaptchaFailed = data.recaptchaFailed ? Date.now() : "";
  const message =
    response?.message ||
    CommonUtils.i18n(
      "session.verified_onborading_basic_details.error_creating_verified_user"
    );
  return {
    type: ActionTypes.VERIFIED_USER_FAILED,
    data: {
      message,
      recaptchaFailed,
      recaptchaSiteKey: data.recaptchaSiteKey,
    },
  };
}

export function createVerfiedUser(verfiedUserObject) {
  return (dispatch) => {
    return AxiosAPIUtils.post(Routes.VERIFIED_USER_API, verfiedUserObject)
      .then((response) => {
        dispatch(createVerfiedUserSuccess(response, verfiedUserObject));
      })
      .catch((response) => {
        dispatch(createVerfiedUserFailed(response.responseJSON));
      });
  };
}
