import React, { useState, useEffect } from "react";
import { CommonUtils, Button, TextField } from "coupa-common-js";
import { useDispatch, useSelector } from "react-redux";
import * as SignupActionCreator from "../../actions/SignupActionCreator";
import * as JoinSuggestionActionCreator from "../../actions/JoinSuggestionActionCreator";
import {
  hasAtleastOneAttribute,
  validateAccountInfo,
} from "../../utils/SessionUtils";
import Recaptcha from "../../../common/components/Recaptcha";
import { Tabs, AccountOptions } from "../../utils/constants";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import AutoCompleteAddress from "../../../common/components/AutoCompleteAddress";
import Immutable from "immutable";
import SupplierCommonUtils from "../../../common/utils/SupplierCommonUtils";

const JoinAccount = () => {
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(AccountOptions.JOIN);
  const [errors, setErrors] = useState({
    business_name: "",
    business_address: {},
    tax_id: "",
    duns: "",
    form: "",
  });
  const [disableAction, setDisableAction] = useState(false);
  const {
    recaptchaFailed,
    recaptchaSiteKey,
    hasError,
    signupObj,
    domain,
    cachedAccountInfo,
    countryList,
    visitorId,
  } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });
  const [accountInfo, setAccountInfo] = useState({
    business_name: CommonUtils.isEmpty(cachedAccountInfo)
      ? signupObj?.supplier?.name
      : cachedAccountInfo?.business_name,
    business_address: cachedAccountInfo?.business_address || {},
    tax_id: cachedAccountInfo?.tax_id || "",
    duns: cachedAccountInfo?.duns || "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const renderHeader = () => {
    return (
      <div className="header">
        <div className="mainHeader">
          {translationScope("join_existing_account")}
        </div>
        <div className="subHeader">
          {translationScope("provide_additional_info")}
        </div>
      </div>
    );
  };

  const onAddressChange = (field, value, autoCompleteAddress) => {
    let businessAddress =
      autoCompleteAddress && field !== "country_id"
        ? autoCompleteAddress
        : calculateBusinessAddress(field, value);

    setAccountInfo({
      ...accountInfo,
      business_address: businessAddress,
    });
    setErrors({
      ...errors,
      business_address: {},
    });
  };

  const calculateBusinessAddress = (field, value) => {
    let oldCountryId = accountInfo?.business_address?.country_id;
    if (field === "country_id" && CommonUtils.isEmpty(value)) {
      return {};
    } else if (
      field === "country_id" &&
      !CommonUtils.isEmpty(oldCountryId) &&
      oldCountryId !== value
    ) {
      // if country is changed, clearing all address fields except country id
      return {
        country_id: value,
      };
    } else {
      return {
        ...accountInfo.business_address,
        [field]: value,
      };
    }
  };

  const translationScope = (key) => {
    return CommonUtils.i18n(`session.join_account.${key}`);
  };

  const formattedCountryList = Immutable.fromJS(countryList);

  const handleSelectedOptionChange = (event) => {
    let optionValue = event.target.closest(".customCheckboxElement").dataset
      .option;
    if (optionValue === AccountOptions.CREATE) {
      setErrors({ business_address: {} });
    }
    setSelectedOption(optionValue);
  };

  const handleChange = (field, value) => {
    setAccountInfo({ ...accountInfo, [field]: value });
    setErrors({ ...errors, [field]: null, form: null });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({ business_address: {} });
    setDisableAction(true);
    if (recaptchaFailed && !recaptchaToken) {
      SupplierCommonUtils.showErrorNotification(
        CommonUtils.i18n("session.recaptcha_check")
      );
      setDisableAction(false);
      return false;
    }
    if (selectedOption === AccountOptions.JOIN) {
      if (!hasAtleastOneAttribute(accountInfo)) {
        SupplierCommonUtils.showErrorNotification(
          translationScope("errors.empty")
        );
        setDisableAction(false);
        return false;
      }

      let errorsList = validateAccountInfo(accountInfo);
      if (hasAtleastOneAttribute(errorsList)) {
        setErrors(errorsList);
        setDisableAction(false);
        return false;
      }
      dispatch(
        JoinSuggestionActionCreator.getJoinSuggestions(accountInfo, {
          ...signupObj,
          "g-recaptcha-response": recaptchaToken,
          visitor_id: visitorId,
        })
      );
      setDisableAction(false);
    } else {
      // proceed to create account
      dispatch(
        SignupActionCreator.createAccount({
          ...signupObj,
          "g-recaptcha-response": recaptchaToken,
          visitor_id: visitorId,
          skip_join_suggestion: true,
          tab: Tabs.JOIN_ACCOUNT,
        })
      );
      setDisableAction(false);
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  const renderRecaptcha = () => {
    if (recaptchaFailed) {
      return (
        <Recaptcha
          siteKey={recaptchaSiteKey}
          onChange={onRecaptchaChange}
          failedTime={recaptchaFailed}
        />
      );
    }
  };

  const joinAccountLabel = () => {
    return (
      <>
        {CommonUtils.i18n("session.join_account.view_existing_accounts")}
        &nbsp;
        <span className="domain">{domain}</span>
      </>
    );
  };

  return (
    <div className="joinAccountContainer">
      <div className="row">
        <div className="col-md-2 hidden-sm"></div>
        <div className="col-md-8">
          <div className="col-md-12">
            <div className="content">
              {renderHeader()}
              <form onSubmit={handleSubmit}>
                <div className="">
                  <CustomCheckbox
                    id={"joinAccount"}
                    label={joinAccountLabel()}
                    checked={selectedOption === AccountOptions.JOIN}
                    value={AccountOptions.JOIN}
                    onChange={handleSelectedOptionChange}
                    className={"joinAccountCheckbox"}
                  >
                    <TextField
                      id="business_name"
                      name="business_name"
                      label={translationScope("business_name")}
                      showMessages={true}
                      value={accountInfo.business_name}
                      onChange={handleChange}
                      errorMessage={errors.business_name}
                      className="formField extraMargin businessName"
                      inputSelector="s-businessName"
                      disabled={selectedOption === AccountOptions.CREATE}
                    />
                    <AutoCompleteAddress
                      address={accountInfo.business_address}
                      errors={errors.business_address || {}}
                      countryList={formattedCountryList}
                      disabled={selectedOption === AccountOptions.CREATE}
                      disableCountry={false}
                      onAddressChange={onAddressChange}
                      purpose={"join_account"}
                      required={false}
                    />
                    <div className="gridContainer">
                      <TextField
                        name="tax_id"
                        label={translationScope("tax_id")}
                        value={accountInfo.tax_id}
                        disabled={selectedOption === AccountOptions.CREATE}
                        inputSelector="s-taxId"
                        showMessages={true}
                        errorMessage={errors.tax_id}
                        onChange={handleChange}
                      />
                      <TextField
                        name="duns"
                        label={translationScope("duns")}
                        value={accountInfo.duns}
                        disabled={selectedOption === AccountOptions.CREATE}
                        inputSelector="s-duns"
                        showMessages={true}
                        errorMessage={errors.duns}
                        onChange={handleChange}
                      />
                    </div>
                  </CustomCheckbox>

                  <CustomCheckbox
                    id={"createAccount"}
                    label={translationScope("continue_with_new_account")}
                    checked={selectedOption === AccountOptions.CREATE}
                    value={AccountOptions.CREATE}
                    onChange={handleSelectedOptionChange}
                  />

                  <span className="errors red center displayBlock">
                    {errors.form}
                  </span>
                  {renderRecaptcha()}
                  <Button
                    type="submit"
                    buttonText={CommonUtils.i18n("session.next")}
                    className="blue btn_margin actionButton s-next joinAccountButton"
                    disabled={disableAction && !recaptchaFailed}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-2 hidden-sm"></div>
      </div>
    </div>
  );
};

export default JoinAccount;
