export const termsAndConditions = {
  terms: "http://www.coupa.com/supplier-terms-and-conditions/",
  privacy:
    "http://www.coupa.com/purchasing-procurement-information/privacy-policy/",
};

export const ActionTypes = {
  INITIAL_STATE: "initial_state",
  LOAD_LOGIN: "load_login",
  SET_VISITOR_ID: "set_visitor_id",
  LOAD_SIGNUP: "load_signup",
  LOAD_FORWARD_INVITATION: "load_forward_invitation",
  LOAD_SIGNUP_WITHOUT_RESET: "load_signup_without_reset",
  LOAD_VERIFIED: "load_verified",
  LOGIN_SUCCESS: "login_success",
  LOGIN_FAILED: "login_failed",
  CSP_LOGIN: "csp_login",
  IDENTITY_LOGIN: "identity_login",
  IDENTITY_FAILED: "identity_failed",
  RESET_MESSAGE: "reset_message",
  SEND_OTP_SUCCESS: "send_otp_success",
  SEND_OTP_FAILED: "send_otp_failed",
  OTP_SUCCESS: "otp_success",
  CREATE_ACCOUNT_SUCCESS: "create_account_success",
  CREATE_ACCOUNT_FAILED: "create_account_failed",
  FORWARD_INVITATION_SUCCESS: "forward_invitation_success",
  FORWARD_INVITATION_FAILED: "forward_invitation_failed",
  RESEND_OTP_FAILED: "resend_otp_failed",
  VERIFIED_USER_SUCCESS: "verified_user_success",
  VERIFIED_USER_FAILED: "verified_user_failed",
  SUGGEST_JOIN_ACCOUNT: "suggest_join_account",
  JOIN_SUGGESTIONS_SUCCESS: "join_suggestions_success",
  JOIN_SUGGESTIONS_FAILED: "join_suggestions_failed",
  JOIN_SUGGESTIONS_EMPTY: "join_suggestions_empty",
  LOAD_JOIN_SUGGESTION: "load_join_suggestion",
  SEND_JOIN_REQUEST_SUCCESS: "send_join_request_success",
  SEND_JOIN_REQUEST_FAILED: "send_join_request_failed",
};

export const Tabs = {
  LANDING: "landing",
  CSP_LOGIN: "csp_login",
  IDENTITY_LOGIN: "identity_login",
  SIGNUP: "signup",
  OTP_VERIFICATION: "otp_verification",
  FORWARD_INVITATION: "forward_invitation",
  PAYMENT: "payment",
  VERIFIED: "verified",
  PREMIUM_SUPPORT: "premium_support",
  COUPA_ADVANCED: "coupa_advanced",
  JOIN_ACCOUNT: "join_account",
  JOIN_SUGGESTIONS: "join_suggestions",
  JOIN_CONFIRMATION: "join_confirmation",
};

export const Routes = {
  COUPA_IDENTITY_LOGIN_PATH: "/oauth2/sessions/coupa_identity/auth",
  LOGIN_API: "/sessions",
  SIGNUP_API: "/users",
  LOGIN_PATH: "/sessions/new",
  SIGNUP_PATH: "/sessions/new?page=signup",
  OTP_VERIFICATION_API: "/invitations/otp",
  HOME_PATH: "/home",
  FORGOT_PASSWORD_PATH: "/forgot_password",
  FORWARD_INVITATION_PATH: "/invite_user",
  VERIFIED_USER_API: "get_verified/verified_users",
  JOIN_SUGGESTIONS_API: "/user_join_requests/match",
  SEND_JOIN_REQUEST_API: "/user_join_requests/request",
  SUPPORT_ARTICLE_URL:
    "/get-started-with-the-csp/registration-and-login/log-in-to-the-csp",
};

export const Source = {
  HOME_PAGE: "homepage",
};

export const AccountOptions = {
  JOIN: "join",
  CREATE: "create",
};

export const ActionTypesVerification = {
  LOAD_LOGIN: "load_login",
  LOAD_SIGNUP: "load_signup",
  REDIRECT_SIGNUP: "redirect_signup",
};
