import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonUtils, AxiosAPIUtils } from "coupa-common-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Routes } from "../utils/AppConstants";
import StripePayment from "./StripePayment";
import { Spinner } from "coupa-common-js/core/Spinner";
import { createStripeIntent } from "../actions/SupplierVerificationActionCreators";

const StripePaymentWrapper = ({
  actionType,
  nextModal,
  getErrors,
  basicData,
  offering,
  trial,
  bundle,
}) => {
  const dispatch = useDispatch();
  const [stripeKey, setStripeKey] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const supplierVerificationReducer = useSelector(
    (state) => state.supplierVerificationReducer
  );

  const [formErrors, setFormErrors] = useState(
    supplierVerificationReducer?.errors?.payment
  );

  const clientSecret = useMemo(() => {
    if (supplierVerificationReducer?.supplierAppData) {
      return supplierVerificationReducer?.supplierAppData?.clientSecret;
    } else {
      return null;
    }
  }, [supplierVerificationReducer?.supplierAppData]);

  const data = useMemo(() => {
    if (supplierVerificationReducer?.supplierAppData) {
      return {
        subscription_id:
          supplierVerificationReducer?.supplierAppData?.subscriptionId,
        subscriber_id:
          supplierVerificationReducer?.supplierAppData?.subscriberId,
        supplier_payment_id:
          supplierVerificationReducer?.supplierAppData?.supplierPayment,
        return_url: supplierVerificationReducer?.supplierAppData?.returnURL,
        subscription_price_usd:
          supplierVerificationReducer?.supplierAppData?.subscriptionPriceUSD,
        allowed_country:
          supplierVerificationReducer?.supplierAppData?.allowedCountry,
        coupon_applied:
          supplierVerificationReducer?.supplierAppData?.couponApplied,
        offering_name:
          supplierVerificationReducer?.supplierAppData?.offeringName,
        coupon_error: supplierVerificationReducer?.supplierAppData?.couponError,
        coupon_error_message:
          supplierVerificationReducer?.supplierAppData?.couponErrorMessage,
        amount_off: supplierVerificationReducer?.supplierAppData?.amountOff,
        percent_off: supplierVerificationReducer?.supplierAppData?.percentOff,
        discounted_amount:
          supplierVerificationReducer?.supplierAppData?.discountedAmount,
        total_due: supplierVerificationReducer?.supplierAppData?.totalDue,
        sub_total: supplierVerificationReducer?.supplierAppData?.subTotal,
        hundred_percent_promo_applied:
          supplierVerificationReducer?.supplierAppData
            ?.hundred_percent_promo_applied,
        client_secret: clientSecret,
        billing_date:
          supplierVerificationReducer?.supplierAppData?.billing_date,
        invoice_lines:
          supplierVerificationReducer?.supplierAppData?.invoice_lines,
        bundled_subscriptions:
          supplierVerificationReducer?.supplierAppData?.bundled_subscriptions,
      };
    } else {
      return null;
    }
  }, [supplierVerificationReducer?.supplierAppData]);

  const locale = useMemo(() => {
    if (supplierVerificationReducer?.supplierAppData) {
      return supplierVerificationReducer?.supplierAppData?.userLocale;
    } else {
      return "en";
    }
  }, [supplierVerificationReducer?.supplierAppData]);

  useEffect(() => {
    setFormErrors(supplierVerificationReducer?.errors?.payment);
  }, [supplierVerificationReducer?.errors]);

  const getStripeKey = async () => {
    const returnedKey = await AxiosAPIUtils.get(Routes.FETCH_STRIPE_KEY)
      .then((response) => {
        return response["publishable_key"];
      })
      .catch(() => {
        setFormErrors(
          CommonUtils.i18n("coupa_verified.errors.payment_initialization")
        );
      });
    return returnedKey;
  };

  const getStripePromise = (stripeKey) => {
    return loadStripe(stripeKey, {
      betas: ["address_element_beta_1"],
    });
  };

  const initCard = (email) => {
    dispatch(
      createStripeIntent({
        email,
        offering,
        bundle,
        trial,
      })
    );
  };

  const initializeStripe = async () => {
    let stripeKey = await getStripeKey();
    setStripePromise(getStripePromise(stripeKey));
  };

  useEffect(() => {
    initializeStripe();
  }, []);

  useEffect(() => {
    if (stripePromise != null) {
      initCard(basicData?.invite_email);
    }
  }, [stripePromise]);

  return (
    <>
      {formErrors && <div className="center bold errors">{formErrors}</div>}
      {stripePromise && clientSecret ? (
        <React.StrictMode>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              locale: locale,
              loader: "always",
            }}
            key={clientSecret}
          >
            <StripePayment
              actionType={actionType}
              nextModal={nextModal}
              getErrors={getErrors}
              data={data}
              basicData={basicData}
              offering={offering}
              trial={trial}
              bundle={bundle}
            />
          </Elements>
        </React.StrictMode>
      ) : (
        !formErrors && (
          <div className="center">
            <Spinner size="large" speed="slow" className="spinner" />
            <div className="center spinnerWait">
              {CommonUtils.i18n("coupa_verified.stripe_modal.payment_wait")}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default StripePaymentWrapper;
