import CommonUtils from "coupa-common-js/core/utils/CommonUtils";
import {
  FILE_SIZE_LIMIT,
  ALLOWED_FILE_TYPES,
  PHONE_REGEX,
  VALID_EMAIL_REGEX,
  OFFERINGS,
  Routes,
} from "./AppConstants";

export const VERIFICATION_ENDPOINTS = {
  SUPPLIER_INFORMATION: Routes.SUPPLIER_INFORMATION,
  SAVE_CONTACT_INFO: Routes.SAVE_CONTACT_INFO,
  SAVE_PAYMENT_INFO: Routes.SAVE_PAYMENT_INFO,
  SAVE_PAYMENT_ERRORS: Routes.SAVE_PAYMENT_ERRORS,
};

export const SUBSCRIPTION_ENDPOINTS = {
  SUPPLIER_INFORMATION: Routes.SUBSCRIPTION_SUPPLIER_INFORMATION,
  SAVE_CONTACT_INFO: Routes.SUBSCRIPTION_SAVE_CONTACT_INFO,
  SAVE_PAYMENT_INFO: Routes.SUBSCRIPTION_SAVE_PAYMENT_INFO,
  SAVE_PAYMENT_ERRORS: Routes.SUBSCRIPTION_SAVE_PAYMENT_ERRORS,
  ACTIVATE_TRIAL: Routes.SUBSCRIPTION_ACTIVATE_TRIAL,
};

export const HUMANIZED_OFFERING_NAME = {
  coupa_verified: CommonUtils.i18n("coupa_verified.title"),
  coupa_advanced: CommonUtils.i18n("coupa_verified.coupa_advanced.title"),
  premium_support: CommonUtils.i18n(
    "coupa_verified.contact_info_modal.premium_support.header"
  ),
};

export const OFFERING_CLASS_NAME = {
  coupa_verified: "coupaVerified",
  coupa_advanced: "coupaAdvanced",
  premium_support: "premiumSupport",
};

export function validateSupplierInformation(formData, keysToValidate) {
  let keys = keysToValidate || Object.keys(formData);
  let errors = {};
  let phoneKey = "phone_number";
  let emailKey = "email";
  if (formData) {
    keys.forEach((key) => {
      if (CommonUtils.isEmpty(formData[key])) {
        errors[key] = CommonUtils.i18n("errors.messages.empty");
      }
    });
    if (
      keys.includes(phoneKey) &&
      !errors[phoneKey] &&
      !validatePhoneNumber(formData[phoneKey])
    ) {
      errors[phoneKey] = CommonUtils.i18n("errors.attributes.phone.invalid");
    }
    if (
      keys.includes(emailKey) &&
      !errors[emailKey] &&
      !validateEmail(formData[emailKey])
    ) {
      errors[emailKey] = CommonUtils.i18n("errors.attributes.email.invalid");
    }
  }
  return errors;
}

export function getEndpointForOffering(actionType, offering) {
  switch (offering) {
    case OFFERINGS.COUPA_VERIFIED:
      return VERIFICATION_ENDPOINTS[actionType];
    case OFFERINGS.COUPA_ADVANCED:
    case OFFERINGS.PREMIUM_SUPPORT:
      return SUBSCRIPTION_ENDPOINTS[actionType];
  }
}

export function getHumanizedOfferingName(offering) {
  return HUMANIZED_OFFERING_NAME[offering];
}

export function createLocalizedSentence(arrayOfItems) {
  return arrayOfItems.join(
    CommonUtils.i18n("support.array.two_words_connector")
  );
}

export function validateFile(file) {
  let error = null;
  if (file.size > FILE_SIZE_LIMIT) {
    error = CommonUtils.i18n("coupa_verified.errors.file_size");
  }
  if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    error = CommonUtils.i18n("coupa_verified.errors.file_type");
  }
  return error;
}

export function validatePhoneNumber(phoneNumber) {
  return PHONE_REGEX.test(phoneNumber);
}

export function validateEmail(email) {
  return VALID_EMAIL_REGEX.test(email);
}

export function combineObjs(...objects) {
  return Object.assign({}, ...objects);
}
