module.exports = {
  EMAIL_REGEX: /^\S+@\S+\.\S+$/,
  recaptchaUrl:
    "https://www.recaptcha.net/recaptcha/api.js?onload=onloadCallback&render=explicit",
  MIN_SEARCH_LEN: 3,
  AutoCompAddress: {
    LOOKUP_API:
      "/google_maps/autocomplete_address?input={term}&country_id={countryId}&purpose=join_account",
    PLACE_DETAILS_API: "/google_maps/place_details/:placeId",
  },
  HTTP_STATUSES: {
    TOO_MANY_REQUESTS: 429,
  },
  ADDR1: 1,
  ADDR2: 2,
  ADDR3: 3,
  ADDR4: 4,
  SIGNUP: "signup",
  PRIMARY: "primary",
  CUSTOM_LABEL_COUNTRIES: [
    "US",
    "BR",
    "CA",
    "SG",
    "NO",
    "MY",
    "MX",
    "JP",
    "IN",
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",
    "ZA",
    "AU",
    "NZ",
  ],
  TAX_REGISTRATION_NOTIFICATION: {
    UPDATE_PROFILE_URL: "/profile/setup/who-we-are/edit",
    PROFILE_ACCESS: "profile_access",
    PAGE: "tax_registration",
    BEFORE_DUE_DATE: "before_due_date",
    AFTER_DUE_DATE: "after_due_date",
    NAME: "tax_registration_notification",
  },
  ROUTES: {
    UPDATE_TAX_REGISTRATION: "/suppliers/update_tax_registration_details",
    LOGOUT: "/sessions/destroy",
  },
};
