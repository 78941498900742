import { AxiosAPIUtils, CommonUtils } from "coupa-common-js";
import { ActionTypes, Routes, Tabs } from "../utils/constants";

export function otpActionSuccess(signupObj, response) {
  return {
    type: ActionTypes.SEND_OTP_SUCCESS,
    data: {
      signupObj,
      message: response.message,
      taxErrors: [],
    },
  };
}

export function otpActionFailed(response, tab) {
  const data = response?.data || {};
  const recaptchaFailed = data.recaptchaFailed ? Date.now() : "";
  const message =
    response?.message ||
    CommonUtils.i18n("session.otp_verification.otp_send_failed");
  const taxErrors = response?.taxErrors || {};
  return {
    type:
      tab == Tabs.SIGNUP
        ? ActionTypes.SEND_OTP_FAILED
        : ActionTypes.RESEND_OTP_FAILED,
    data: {
      message,
      recaptchaFailed,
      recaptchaSiteKey: data.recaptchaSiteKey,
      taxErrors: taxErrors,
    },
  };
}

export function sendOTP(signupObj, tab) {
  return (dispatch) => {
    return AxiosAPIUtils.post(Routes.OTP_VERIFICATION_API, {
      email: signupObj.user.email,
      "g-recaptcha-response": signupObj["g-recaptcha-response"],
      visitor_id: signupObj.visitor_id,
      tax_registration: signupObj.tax_registration,
    })
      .then((response) => {
        dispatch(otpActionSuccess(signupObj, response));
      })
      .catch((response) => {
        dispatch(otpActionFailed(response.responseJSON, tab));
      });
  };
}
