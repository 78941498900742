import React, { useState, useEffect } from "react";
import { CommonUtils, TextField, Button, Link } from "coupa-common-js";
import { Spinner } from "coupa-common-js/core/Icon";
import { ActionTypes, Routes, Tabs } from "../../utils/constants";
import * as loginActionCreator from "../../actions/LoginActionCreator";
import ReachLink from "supplier-common-ui/dist/components/ReachLink";
import Recaptcha from "../../../common/components/Recaptcha";
import { useDispatch, useSelector } from "react-redux";
import * as SessionUtils from "../../utils/SessionUtils";
import "./Login.scss";

const Login = () => {
  const dispatch = useDispatch();

  const {
    tab,
    recaptchaFailed,
    recaptchaSiteKey,
    hasError,
    message,
    redirectTo,
    identityLogin,
    skipSignup,
    visitorId,
  } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });
  const [email, setEmail] = useState({ value: "", errorMessage: "" });
  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [disableLogin, setDisableLogin] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loaderHeight, setLoaderHeight] = useState(0);

  useEffect(() => {
    if (!redirectTo) setDisableLogin(false);
  }, [hasError, message]);

  useEffect(() => {
    if (!hasError) setDisableLogin(false);
  }, [tab]);

  const handleEmailChange = (field, value) => {
    setEmail({ value, errorMessage: "" });
  };

  const handlePasswordChange = (field, value) => {
    setPassword({ value, errorMessage: "" });
  };

  const isValidLogin = () => {
    if (!email.value) {
      setEmail({
        value: email.value,
        errorMessage: CommonUtils.i18n("session.email_empty"),
      });
      return false;
    }
    if (!SessionUtils.isValidEmail(email.value)) {
      setEmail({
        value: email.value,
        errorMessage: CommonUtils.i18n("session.email_invalid"),
      });
      return false;
    }
    if (tab === Tabs.CSP_LOGIN && !password.value) {
      setPassword({
        value: password.value,
        errorMessage: CommonUtils.i18n("session.password_empty"),
      });
      return false;
    }
    if (recaptchaFailed && !recaptchaToken) {
      SupplierCommonUtils.showErrorNotification(
        CommonUtils.i18n("session.recaptcha_check")
      );
      return false;
    }
    return true;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setDisableLogin(true);
    if (isValidLogin()) {
      dispatch(
        loginActionCreator.login(
          {
            email: email.value,
            password: password.value,
            "g-recaptcha-response": recaptchaToken,
            visitor_id: visitorId,
            page: tab,
          },
          showLoading,
          hideLoading
        )
      );
    } else {
      setDisableLogin(false);
    }
  };

  const loginOffsetHeight = () => {
    return (
      document.getElementById("login")?.querySelector(".rowGroup")
        ?.offsetHeight || 209
    );
  };
  const showLoading = () => {
    setLoaderHeight(loginOffsetHeight);
    setShowLoader(true);
  };

  const hideLoading = () => {
    setShowLoader(false);
    setLoaderHeight(0);
  };

  const handleCreateAccount = () => {
    dispatch({ type: ActionTypes.LOAD_SIGNUP });
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  const renderRecaptcha = () => {
    if (recaptchaFailed) {
      return (
        <Recaptcha
          siteKey={recaptchaSiteKey}
          onChange={onRecaptchaChange}
          failedTime={recaptchaFailed}
        />
      );
    }
  };

  const IframeLoader = () => (
    <div id="iframeLoadingSpinner" style={{ height: loaderHeight }}>
      <Spinner />
      <div>{CommonUtils.i18n("defaults.loading")}</div>
    </div>
  );

  const renderHeader = () => {
    return (
      <div className="header">
        <div className="mainHeader s-mainHeader">
          {CommonUtils.i18n("session.login.header")}
        </div>
      </div>
    );
  };

  return (
    <div id="login">
      {!identityLogin && (
        <div className="rowGroup">
          <div className="row">
            <div className="col-sm-3 hide_on_mobile"></div>
            <div className="col-sm-6">{renderHeader()}</div>
            <div className="col-sm-3 hide_on_mobile"></div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <div className="content">
                <form onSubmit={handleFormSubmit}>
                  <TextField
                    id="email"
                    name="email"
                    label={CommonUtils.i18n("session.signup.email")}
                    requiredField={true}
                    showMessages={true}
                    value={email.value}
                    onInput={handleEmailChange}
                    errorMessage={email.errorMessage}
                    className="formField s-emailField"
                    inputSelector="s-email"
                    autoFocus={CommonUtils.isEmpty(email.value)}
                    inputProps={{ autoComplete: "email" }}
                  />
                  {tab === Tabs.CSP_LOGIN ? (
                    <TextField
                      id="password"
                      name="password"
                      type="password"
                      label={CommonUtils.i18n("session.signup.password")}
                      requiredField={true}
                      showMessages={true}
                      value={password.value}
                      onInput={handlePasswordChange}
                      errorMessage={password.errorMessage}
                      className="formField"
                      inputSelector="s-password"
                      autoFocus={!CommonUtils.isEmpty(email.value)}
                    />
                  ) : (
                    <TextField
                      className="formField s-hiddenPasswordField hiddenPasswordField"
                      id="password"
                      name="password"
                      type="password"
                      inputProps={{
                        autoComplete: "password",
                        className: "hiddenPasswordInput",
                      }}
                    />
                  )}
                  {renderRecaptcha()}
                  <div className="button-group">
                    <Button
                      type="submit"
                      buttonText={CommonUtils.i18n(
                        `session.login.${
                          tab === Tabs.CSP_LOGIN ? "button_label" : "continue"
                        }`
                      )}
                      className="mt-2 roundedButton blue btn_margin s-login actionButton nopadding"
                      disabled={disableLogin}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-sm-3 hide_on_mobile"></div>
          </div>
        </div>
      )}

      {showLoader && <IframeLoader />}

      <iframe id="identityLoginIframe" hidden></iframe>

      <div className="content createAccount">
        <div className="button-group">
          {!skipSignup && (
            <div className="flexDisplay">
              <span className="info_color s-newToCoupa">
                {CommonUtils.i18n("session.login.new_to_coupa")}
              </span>
              <ReachLink to={Routes.SIGNUP_PATH}>
                <Button
                  buttonText={CommonUtils.i18n(
                    "session.signup.create_account_all_caps"
                  )}
                  className="buttonAsLink btn_highlightcolor s-createAccountLink"
                  onClick={handleCreateAccount}
                />
              </ReachLink>
            </div>
          )}
          <Link
            href={Routes.FORGOT_PASSWORD_PATH}
            className="forgotPassword s-forgotPassword"
          >
            {CommonUtils.i18n("session.login.forgot_password")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
