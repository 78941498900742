import React, { useEffect } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { CommonUtils } from "coupa-common-js";
import Signup from "./Signup/Signup";
import Login from "./Login/Login";
import OtpVerification from "./OtpVerification/OtpVerification";
import ForwardInvitation from "./ForwardInvitation/ForwardInvitation";
import StripePaymentWrapper from "../../../../../engines/get_verified/app/javascript/src/get_verified/supplier_verification/components/StripePaymentWrapper";
import { OFFERINGS } from "../../../../../engines/get_verified/app/javascript/src/get_verified/supplier_verification/utils/AppConstants.js";
import JoinAccount from "./JoinAccount/JoinAccount";
import JoinAccountSuggestions from "./JoinAccountSuggestions/JoinAccountSuggestions";
import JoinRequestConfirmation from "./JoinRequestConfirmation/JoinRequestConfirmation";
import "./Session.scss";
import { ActionTypes, Tabs, Routes } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import * as SessionActionCreator from "../actions/SessionActionCreator";
import SupplierCommonUtils from "../../common/utils/SupplierCommonUtils";

const SessionApp = () => {
  const dispatch = useDispatch();

  const {
    isLoggedIn,
    tab,
    offering,
    redirectTo,
    hasError,
    helpCenterSupplierOnboardingUrl,
    message,
    invite_email,
    first_name,
    last_name,
    signupUrl,
  } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });

  const renderComponent = () => {
    switch (tab) {
      case Tabs.SIGNUP:
      case Tabs.VERIFIED:
      case Tabs.PREMIUM_SUPPORT:
      case Tabs.COUPA_ADVANCED:
        return <Signup />;
      case Tabs.OTP_VERIFICATION:
        return <OtpVerification />;
      case Tabs.FORWARD_INVITATION:
        return <ForwardInvitation />;
      case Tabs.PAYMENT:
        return (
          <div className="verification-modal-container">
            <StripePaymentWrapper
              basicData={{
                invite_email,
                first_name,
                last_name,
                signupUrl,
              }}
              offering={offering}
            />
          </div>
        );
      case Tabs.JOIN_ACCOUNT:
        return <JoinAccount />;
      case Tabs.JOIN_SUGGESTIONS:
        return <JoinAccountSuggestions />;
      case Tabs.JOIN_CONFIRMATION:
        return <JoinRequestConfirmation />;
      case Tabs.CSP_LOGIN:
        return <Login />;
      default:
        return <Login />;
    }
  };

  useEffect(() => {
    if (isLoggedIn) window.location = redirectTo;
  }, [isLoggedIn]);

  useEffect(() => {
    if (hasError || message) {
      dispatch({ type: ActionTypes.RESET_MESSAGE });
    }
  }, [hasError, message]);

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();

      dispatch({
        type: ActionTypes.SET_VISITOR_ID,
        data: { visitorId: visitorId },
      });
    };

    setFp();
  }, []);

  const renderMessage = () => {
    if (hasError && message) {
      if (
        message === CommonUtils.i18n("session.email_invalid") &&
        (tab === Tabs.SIGNUP || tab === Tabs.VERIFIED)
      ) {
        const msg = `${message} <a href=${helpCenterSupplierOnboardingUrl}/${
          Routes.SUPPORT_ARTICLE_URL
        } target="_blank">${CommonUtils.i18n("session.support_article")}</a>`;
        SupplierCommonUtils.showErrorNotification(msg);
      }
      SupplierCommonUtils.showErrorNotification(message);
    } else if (message) {
      SupplierCommonUtils.showNotification(message);
    }
  };

  return (
    <div>
      <div className="authCard">
        {renderMessage()}
        <div className="sprite-session-icon uiHeader"></div>
        {renderComponent()}
      </div>
    </div>
  );
};

export default SessionApp;
