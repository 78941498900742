import React, { useState, useEffect, useMemo } from "react";
import { CommonUtils, Button, WithTooltip } from "coupa-common-js";
import { useDispatch, useSelector } from "react-redux";
import * as JoinSuggestionActionCreator from "../../actions/JoinSuggestionActionCreator";
import * as SignupActionCreator from "../../actions/SignupActionCreator";
import Recaptcha from "../../../common/components/Recaptcha";
import { Tabs, AccountOptions, ActionTypes } from "../../utils/constants";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import {
  formatAddress,
  hasAtleastOneAttribute,
  formatAdminUsers,
} from "../../utils/SessionUtils";

const JoinAccountSuggestions = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(AccountOptions.CREATE);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const {
    recaptchaFailed,
    recaptchaSiteKey,
    hasError,
    suppliersList,
    signupObj,
    cachedAccountInfo,
    domain,
    visitorId,
  } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });

  useEffect(() => {
    if (hasSuggestions) {
      setSelectedOption(suppliersList[0].supplier_id);
    }
  }, []);

  const translationScope = (key, attrs = null) => {
    return CommonUtils.i18n(`session.join_account.${key}`, attrs);
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <div className="mainHeader">
          {translationScope("join_existing_account")}
        </div>
        <div className="subHeader">
          {translationScope("similar_accounts_exist", { domain: `@${domain}` })}
          &nbsp;
          {translationScope("join_or_create")}
        </div>
      </div>
    );
  };

  const renderSuggestedSuppliers = (suppliersList) => {
    return (
      <>
        {suppliersList.map((supplier) => {
          return (
            <WithTooltip
              key={supplier.supplier_id}
              tooltipType={"info"}
              tooltipContent={`${translationScope("admin")}${formatAdminUsers(
                supplier.admin_users
              )}`}
              className="customTooltip"
              delayShow={100}
              delayHide={0}
              isChildFocusable={true}
            >
              <CustomCheckbox
                key={supplier.supplier_id}
                id={`supplierSuggestion${supplier.supplier_id}`}
                label={supplier.supplier_name}
                checked={selectedOption == supplier.supplier_id}
                value={supplier.supplier_id}
                name={"selectedSuggestion"}
                onChange={handleSelectedOptionChange}
              >
                <div className="supplierDetails">
                  {hasAtleastOneAttribute(supplier.primary_address, true) && (
                    <>
                      <span className="supplierAddress">
                        {formatAddress(supplier.primary_address)}
                      </span>
                      <br />
                    </>
                  )}
                  <span className="supplierUsers">
                    {translationScope("active_members", {
                      count: supplier.users_count,
                    })}
                    <span className="activeMembers">
                      {formatAdminUsers(supplier.admin_users)}
                    </span>
                  </span>
                  <br />
                </div>
              </CustomCheckbox>
            </WithTooltip>
          );
        })}
      </>
    );
  };

  const handleSelectedOptionChange = (event) => {
    setSelectedOption(
      event.target.closest(".customCheckboxElement").dataset.option
    );
  };

  const handleSubmit = () => {
    if (selectedOption === AccountOptions.CREATE) {
      dispatch(
        SignupActionCreator.createAccount({
          ...signupObj,
          "g-recaptcha-response": recaptchaToken,
          visitor_id: visitorId,
          skip_join_suggestion: true,
          tab: Tabs.JOIN_SUGGESTIONS,
        })
      );
    } else {
      dispatch(
        JoinSuggestionActionCreator.sendJoinRequest(
          {
            ...cachedAccountInfo,
            target_supplier_id: selectedOption,
          },
          {
            ...signupObj,
            "g-recaptcha-response": recaptchaToken,
            visitor_id: visitorId,
          }
        )
      );
    }
  };

  const handleBack = () => {
    dispatch({
      type: ActionTypes.LOAD_JOIN_SUGGESTION,
    });
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  const renderRecaptcha = () => {
    if (recaptchaFailed) {
      return (
        <Recaptcha
          siteKey={recaptchaSiteKey}
          onChange={onRecaptchaChange}
          failedTime={recaptchaFailed}
        />
      );
    }
  };

  const hasSuggestions = useMemo(() => {
    return suppliersList && suppliersList.length > 0;
  }, [suppliersList]);

  return (
    <div className="joinAccountSuggestionsContainer">
      <div className="row">
        <div className="col navigation">
          <Button
            buttonText={CommonUtils.i18n("session.back")}
            className="buttonAsLink back s-back"
            onClick={handleBack}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <div className="content">{renderHeader()}</div>
        </div>
      </div>

      <div className="row supplierOptionsList">
        <div className="col-md-8 col-md-offset-2">
          <div className="content supplierOptions">
            {hasSuggestions ? (
              renderSuggestedSuppliers(suppliersList)
            ) : (
              <span className="red errors center displayBlock">
                {translationScope("no_matching_accounts")}
              </span>
            )}
            <div>
              <CustomCheckbox
                id="createAccount"
                label={translationScope("continue_with_new_account")}
                checked={selectedOption === AccountOptions.CREATE}
                value={AccountOptions.CREATE}
                name="selectedSuggestion"
                onChange={handleSelectedOptionChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <div className="content">
            {renderRecaptcha()}
            <Button
              type="submit"
              buttonText={CommonUtils.i18n("session.next")}
              className="mt-4 blue nopadding s-next"
              disabled={false}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinAccountSuggestions;
