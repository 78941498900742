import React, { useEffect, useState } from "react";
import Select from "supplier-common-ui/dist/components/Select";
import useId from "supplier-common-ui/dist/hooks/useId";
import DescriptionItem from "reactAppRoute/profile/components/DescriptionItem/DescriptionItem";
import {
  CUSTOM_LABEL_COUNTRIES,
  SIGNUP,
  TAX_REGISTRATION_NOTIFICATION,
} from "../../../utils/AppConstants";
import { CommonUtils, IconButton } from "coupa-common-js";
import { TaxIds } from "reactAppRoute/profile/interfaces";

export interface TaxRegProps {
  countries: {
    label: string;
    value: number;
    code: string;
  }[];
  value: TaxIds;
  index: number;
  showDeleteIcon: boolean;
  disabled: boolean;
  taxError: any;
  requiredFields: boolean;
  page?: string;
  handleOnChange: (countryId: number, taxNumber: string, index: number) => void;
  removeTaxId: (index: number) => void;
}

const TaxReg = ({
  countries,
  value: { tax_number, tax_country_id },
  index,
  showDeleteIcon,
  disabled,
  taxError,
  requiredFields,
  page,
  handleOnChange,
  removeTaxId,
}: TaxRegProps) => {
  const taxCountry = useId();
  const [taxNumber, setTaxNumber] = useState();
  const [taxCountryId, setTaxCountryId] = useState();

  const [registrationLabel, setregistrationLabel] = useState(
    CommonUtils.i18n("defaults.tax_registrations.tax_label.default")
  );

  const [registrationTooltip, setregistrationTooltip] = useState(
    CommonUtils.i18n("defaults.tax_registrations.tooltip.default")
  );

  useEffect(() => {
    const country = countries.find(
      (element) =>
        element.value == taxCountryId &&
        CUSTOM_LABEL_COUNTRIES.includes(element.code)
    );

    let code = country?.code ?? "default";

    setregistrationLabel(
      CommonUtils.i18n(`defaults.tax_registrations.tax_label.${code}`)
    );

    setregistrationTooltip(
      CommonUtils.i18n(`defaults.tax_registrations.tooltip.${code}`)
    );
  }, [taxCountryId]);

  useEffect(() => {
    setTaxCountryId(tax_country_id);
    setTaxNumber(tax_number);
  }, [tax_country_id, tax_number]);

  function processErrors(errors, errorType) {
    let errorLabel = "";
    if (!CommonUtils.isEmpty(errors?.[errorType])) {
      const errorMessages = errors[errorType];
      errorLabel =
        errorMessages.length > 1 ? errorMessages.join("\n") : errorMessages[0];
    }
    return errorLabel;
  }

  const taxErrors = taxError?.errors;
  let taxNumErrorLabel = processErrors(taxErrors, "tax_number");
  let taxCountryErrorLabel = processErrors(taxErrors, "tax_country");

  const taxRegistrationLabelContent = (
    <DescriptionItem
      className={
        [SIGNUP, TAX_REGISTRATION_NOTIFICATION.NAME].includes(page) &&
        "coupaUI taxRegistrationLabel"
      }
      label={registrationLabel}
      description={registrationTooltip}
    />
  );

  const handleCountrySelect = (id) => {
    setTaxCountryId(id);
    handleOnChange(id, taxNumber, index);
  };

  const handleTaxIdChange = (evt) => {
    const value = evt.target.value;
    setTaxNumber(value);
    handleOnChange(taxCountryId, value, index);
  };

  const handleRemoveTaxId = () => {
    removeTaxId(index);
  };

  return (
    <>
      <div className="bs4-row taxRegistrationRow">
        <div
          className="mt-4 bs4-col-12 bs4-col-md-5 bs4-col-lg-5 taxInput"
          data-testid="taxCountryId"
        >
          <label
            className="label label-default taxRegistrationLabel taxCountryLabel"
            htmlFor={taxCountry}
          >
            <span className="taxFieldsRequired" aria-hidden="true">
              *
            </span>
            {CommonUtils.i18n("defaults.tax_registrations.country_region")}
          </label>
          <Select
            id={taxCountry}
            className="bs4-form-control mt-2 taxCountry s-taxCountry"
            value={taxCountryId}
            options={countries}
            onChange={handleCountrySelect}
            disabled={disabled}
            data-testid="taxCountry"
          />
          {taxCountryErrorLabel && (
            <div className="taxError mt-2">{taxCountryErrorLabel}</div>
          )}
        </div>
        <div className="mt-4 bs4-col-12 bs4-col-md-5 bs4-col-lg-5 taxInput">
          <label
            className="label label-default taxRegistrationLabel"
            htmlFor="taxId"
          >
            <span className="taxFieldsRequired" aria-hidden="true">
              *
            </span>
            {taxRegistrationLabelContent}
          </label>
          <input
            data-testid="taxId"
            name="taxId"
            id="taxId"
            className="bs4-form-control mt-2 s-taxId"
            type="text"
            required={requiredFields}
            placeholder={CommonUtils.i18n(
              "defaults.tax_registrations.tax_registration_placeholder"
            )}
            onChange={(evt) => handleTaxIdChange(evt)}
            value={taxNumber ?? ""}
            disabled={disabled}
          />
          {taxNumErrorLabel && (
            <div className="taxError mt-2">{taxNumErrorLabel}</div>
          )}
        </div>
        {showDeleteIcon && (
          <div className="bs4-col-12 bs4-col-md-1 bs4-col-lg-1 deleteTaxId">
            <IconButton
              className="icon icon_button sprite-delete_large mb-1 s-deleteTaxId"
              buttonProps={{ "data-testid": "deleteTaxId" }}
              onClick={handleRemoveTaxId}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TaxReg;
