import { AxiosAPIUtils, CommonUtils } from "coupa-common-js";
import { ActionTypes, Routes } from "../utils/constants";

export function forwardInvitationSuccess(forwardInvitationObject, response) {
  const message =
    response?.message || CommonUtils.i18n("session.invitation_success");
  return {
    type: ActionTypes.FORWARD_INVITATION_SUCCESS,
    data: {
      forwardInvitationObject,
      message,
    },
  };
}

export function forwardInvitationFailed(response) {
  const data = response?.data || {};
  const recaptchaFailed = data.recaptchaFailed ? Date.now() : "";
  const message = response?.message;
  return {
    type: ActionTypes.FORWARD_INVITATION_FAILED,
    data: {
      message,
      recaptchaFailed,
    },
  };
}

export function forwardInvitation(forwardInvitationObject) {
  return (dispatch) => {
    return AxiosAPIUtils.post(
      Routes.FORWARD_INVITATION_PATH,
      forwardInvitationObject
    )
      .then((response) => {
        dispatch(forwardInvitationSuccess(forwardInvitationObject, response));
      })
      .catch((response) => {
        dispatch(forwardInvitationFailed(response.responseJSON));
      });
  };
}
