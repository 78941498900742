import React, { useEffect, useState } from "react";
import {
  CommonUtils,
  TextField,
  CheckBox,
  Button,
  Icon,
  SelectField,
  Link,
} from "coupa-common-js";
import sanitizeContent from "coupa-common-js/core/utils/SanitizeUtils";
import { termsAndConditions, ActionTypes, Tabs } from "../../utils/constants";
import ReachLink from "supplier-common-ui/dist/components/ReachLink";
import { Routes } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import * as OtpActionCreator from "../../actions/OtpActionCreator";
import Recaptcha from "../../../common/components/Recaptcha";
import * as SessionUtils from "../../utils/SessionUtils";
import classNames from "classnames";
import SupplierCommonUtils from "../../../common/utils/SupplierCommonUtils";
import * as SignupActionCreator from "../../actions/SignupActionCreator";
import * as VerifiedOnboardingActionCreator from "../../actions/VerifiedOnboardingActionCreator";
import phoneSupportImg from "assets/images/phone_support.svg";
import clockImg from "assets/images/clock.svg";
import tailoredSupportImg from "assets/images/tailored_support.svg";
import coupaAdvancedFlower from "assets/images/coupa_advanced_flower.svg";
import coupaAdvancedImg from "assets/images/coupa_advanced.png";
import TaxRegistrationsEdit from "reactAppRoute/common/components/TaxRegistrations/TaxRegistrationsEdit/TaxRegistrationsEdit";

const Signup = () => {
  const dispatch = useDispatch();

  const {
    recaptchaFailed,
    recaptchaSiteKey,
    hasError,
    message,
    invite_email,
    supplier_name,
    invite_code,
    campaign,
    customer,
    first_name,
    last_name,
    can_edit_business_name,
    welcome_message,
    redirectTo,
    enableForwardInvite,
    tab,
    offering,
    business_name,
    is_verified,
    visitorId,
    countries,
    taxErrors,
  } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });

  const [businessName, setBusinessName] = useState({
    value: supplier_name || business_name || "",
    errorMessage: "",
    disabled: !can_edit_business_name,
  });

  const [email, setEmail] = useState({
    value: invite_email || "",
    errorMessage: "",
    disabled: !!invite_email,
  });

  const [password, setPassword] = useState({ value: "", errorMessage: "" });

  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    errorMessage: "",
  });

  const [terms, setTerms] = useState({ value: false, errorMessage: "" });
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const [passwordEye, setPasswordEye] = useState({
    shown: false,
    type: "password",
    class: "fa-eye-slash",
  });

  const [role, setRole] = useState({
    value: SessionUtils.roleOptions.getIn([0, "id"]),
    roleValue: "",
    errorMessage: "",
    roleErrorMessage: "",
    showRole: false,
  });

  const [firstName, setFirstName] = useState({
    value: first_name || "",
    errorMessage: "",
  });

  const [lastName, setLastName] = useState({
    value: last_name || "",
    errorMessage: "",
  });

  const [phoneNumber, setphoneNumber] = useState({
    value: "",
    errorMessage: "",
  });

  const [disableSignup, setDisableSignup] = useState(false);

  const [taxRegistration, setTaxRegisration] = useState({
    tax_country_id: null,
    tax_number: "",
    skip_tax_number: false,
    skip_tax_number: "",
  });

  const [taxRegErrors, setTaxRegErrors] = useState(taxErrors);

  useEffect(() => {
    if (!redirectTo) setDisableSignup(false);
  }, [hasError, message]);

  useEffect(() => {
    setTaxRegErrors(taxErrors);
  }, [taxErrors]);

  const isValidField = () => {
    if (CommonUtils.isEmpty(businessName.value)) {
      setBusinessName({
        value: businessName.value,
        errorMessage: CommonUtils.i18n("session.business_name_empty"),
      });
      return false;
    }

    if (!email.value || !SessionUtils.isValidEmail(email.value)) {
      let message = !email.value
        ? CommonUtils.i18n("session.email_empty")
        : CommonUtils.i18n("session.email_invalid");
      setEmail({
        value: email.value,
        errorMessage: message,
      });
      return false;
    }

    if (CommonUtils.isEmpty(firstName.value)) {
      setFirstName({
        value: firstName.value,
        errorMessage: CommonUtils.i18n("session.first_name_empty"),
      });
      return false;
    }

    if (CommonUtils.isEmpty(lastName.value)) {
      setLastName({
        value: lastName.value,
        errorMessage: CommonUtils.i18n("session.last_name_empty"),
      });
      return false;
    }

    if (tab == Tabs.SIGNUP) {
      if (campaign && !role.value) {
        setRole({
          ...role,
          errorMessage: CommonUtils.i18n("session.role_option_invalid"),
        });
        return false;
      }

      if (campaign && role.showRole && CommonUtils.isEmpty(role.roleValue)) {
        setRole({
          ...role,
          roleErrorMessage: CommonUtils.i18n("session.role_invalid"),
        });
        return false;
      }

      if (!password.value) {
        setPassword({
          value: password.value,
          errorMessage: CommonUtils.i18n("session.password_empty"),
        });
        return false;
      }

      if (!SessionUtils.isValidPassword(password.value)) {
        setPassword({
          value: password.value,
          errorMessage: CommonUtils.i18n("session.password_invalid"),
        });
        return false;
      }

      if (!confirmPassword.value) {
        setConfirmPassword({
          value: confirmPassword.value,
          errorMessage: CommonUtils.i18n("session.confirm_password_empty"),
        });
        return false;
      }

      if (password.value !== confirmPassword.value) {
        setConfirmPassword({
          value: confirmPassword.value,
          errorMessage: CommonUtils.i18n("session.confirm_password_invalid"),
        });
        return false;
      }

      setTaxRegErrors([]);
      if (
        !taxRegistration.skip_tax_number &&
        CommonUtils.isEmpty(taxRegistration.tax_country_id)
      ) {
        setTaxRegErrors([
          {
            errors: {
              tax_country: [CommonUtils.i18n("session.tax_empty")],
            },
          },
        ]);
        return false;
      }

      if (
        !taxRegistration.skip_tax_number &&
        CommonUtils.isEmpty(taxRegistration.tax_number)
      ) {
        setTaxRegErrors([
          {
            errors: {
              tax_number: [CommonUtils.i18n("session.tax_empty")],
            },
          },
        ]);
        return false;
      }

      if (
        taxRegistration.skip_tax_number &&
        CommonUtils.isEmpty(taxRegistration.skip_tax_reason)
      ) {
        setTaxRegErrors([
          {
            errors: {
              skip_tax_reason: [CommonUtils.i18n("session.tax_empty")],
            },
          },
        ]);
        return false;
      }

      if (!terms.value) {
        setTerms({
          value: terms.value,
          errorMessage: CommonUtils.i18n("session.terms_invalid"),
        });
        return false;
      }
    }

    if (isUngatedSubscriptionsTab(tab)) {
      if (
        !phoneNumber.value ||
        !SessionUtils.isValidPhoneNumber(phoneNumber.value)
      ) {
        let message = !phoneNumber.value
          ? CommonUtils.i18n("session.phone_number_empty")
          : CommonUtils.i18n("session.phone_number_invalid");
        setphoneNumber({
          value: phoneNumber.value,
          errorMessage: message,
        });
        return false;
      }
    }

    if (recaptchaFailed && !recaptchaToken) {
      SupplierCommonUtils.showErrorNotification(
        CommonUtils.i18n("session.recaptcha_check")
      );
      return false;
    }
    return true;
  };

  const isUngatedSubscriptionsTab = (tab) => {
    return [Tabs.VERIFIED, Tabs.COUPA_ADVANCED, Tabs.PREMIUM_SUPPORT].includes(
      tab
    );
  };

  const handleTermsChange = (field, value) => {
    const val = value === "1";
    setTerms({ value: val, errorMessage: "" });
  };

  const handleBusinessNameChange = (field, value) => {
    setBusinessName({ value, errorMessage: "" });
  };

  const handlephoneNumberChange = (field, value) => {
    setphoneNumber({ value, errorMessage: "" });
  };

  const handleEmailChange = (field, value) => {
    setEmail({ value, errorMessage: "" });
  };

  const handlePasswordChange = (field, value) => {
    setPassword({ value, errorMessage: "" });
  };

  const handleConfirmPasswordChange = (field, value) => {
    setConfirmPassword({ value, errorMessage: "" });
  };

  const handleLogin = () => {
    dispatch({ type: ActionTypes.LOAD_LOGIN });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidField()) {
      setDisableSignup(true);

      if (isUngatedSubscriptionsTab(tab)) {
        dispatch(
          VerifiedOnboardingActionCreator.createVerfiedUser({
            email: email.value,
            offering: offering,
            user_details: {
              first_name: firstName.value,
              last_name: lastName.value,
              business_name: businessName.value,
              phone_number: phoneNumber.value,
            },
            "g-recaptcha-response": recaptchaToken,
            visitor_id: visitorId,
          })
        );
      } else {
        const signupObj = {
          supplier: {
            name: businessName.value,
          },
          user: {
            skip_validation_for_invite: "true",
            email: email.value,
            password: password.value,
          },
          contact: {
            first_name: firstName.value,
            last_name: lastName.value,
          },
          invite_code,
          agreement: terms.value,
          "g-recaptcha-response": recaptchaToken,
          visitor_id: visitorId,
          is_verified,
          tax_registration: taxRegistration,
        };
        if (invite_code && !is_verified) {
          if (campaign) {
            signupObj.user.supplier_department_id = role.value;

            if (role.showRole)
              signupObj.user.user_specified_role = role.roleValue;
          }

          dispatch(
            OtpActionCreator.sendOTP({ ...signupObj, invite_code }, Tabs.SIGNUP)
          );
        } else {
          dispatch(OtpActionCreator.sendOTP(signupObj, Tabs.SIGNUP));
        }
      }
    }
  };

  const handleEyeClick = () => {
    if (passwordEye.shown) {
      setPasswordEye({
        shown: false,
        type: "password",
        class: "fa-eye-slash",
      });
    } else {
      setPasswordEye({
        shown: true,
        type: "text",
        class: "fa-eye",
      });
    }
  };

  const handleForwardInvitation = () => {
    dispatch({ type: ActionTypes.LOAD_FORWARD_INVITATION });
  };

  const renderSignupHeader = () => {
    return (
      <div className="header">
        <div className="mainHeader">
          {CommonUtils.i18n("session.signup.create_account")}
        </div>
        <div className="subHeader">
          {welcome_message ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeContent(welcome_message),
              }}
            />
          ) : (
            CommonUtils.i18n("session.signup.grow_business_sub_heading")
          )}
        </div>
      </div>
    );
  };

  const renderVerifiedHeader = () => {
    return (
      <div className="header">
        <div className="verifiedBadge">
          <i>
            <span className="verifiedBadge__text">
              {CommonUtils.i18n(
                "session.verified_onborading_basic_details.verified"
              )}
            </span>
            <sup className="verifiedBadge__trademark">
              {CommonUtils.i18n(
                "session.verified_onborading_basic_details.trademark"
              )}
            </sup>
          </i>
          <Icon className="icon icon_button sprite-badgeVerified verifiedBadge__icon" />
        </div>
        <div className="subHeader verifiedBadge__subHeader">
          {CommonUtils.i18n(
            "session.verified_onborading_basic_details.sub_header"
          )}
        </div>
      </div>
    );
  };

  const renderAdvancedHeader = () => {
    return (
      <div className="header">
        <div className="verifiedBadge">
          <img
            className="verifiedBadge__icon advancedLogo s-advancedLogo"
            src={coupaAdvancedImg}
          />
        </div>
        <div className="subHeader verifiedBadge__subHeader">
          {CommonUtils.i18n("session.advanced_onboarding.sub_header")}
        </div>
      </div>
    );
  };

  const renderPremiumSupportHeader = () => {
    return (
      <div className="supplierInfoFormHeader premiumSupport">
        <div className={"infoText"}>
          {CommonUtils.i18n(
            "coupa_verified.contact_info_modal.premium_support.header"
          )}
        </div>
        <div className="subHeaderText">
          {CommonUtils.i18n(
            "coupa_verified.contact_info_modal.premium_support.subheading"
          )}
        </div>
        <div className="image-grid">
          <div className="image-cell">
            <img src={phoneSupportImg} className="phoneSupportImg image" />
            <p>
              {CommonUtils.i18n(
                "coupa_verified.contact_info_modal.premium_support.phone_support"
              )}
            </p>
          </div>
          <div className="image-cell">
            <img src={clockImg} className="clockImg image" />
            <p>
              {CommonUtils.i18n(
                "coupa_verified.contact_info_modal.premium_support.faster_response"
              )}
            </p>
          </div>
          <div className="image-cell">
            <img
              src={tailoredSupportImg}
              className="tailoredSupportImg image"
            />
            <p>
              {CommonUtils.i18n(
                "coupa_verified.contact_info_modal.premium_support.tailored_support"
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    if (tab === Tabs.VERIFIED) {
      return renderVerifiedHeader();
    } else if (tab === Tabs.COUPA_ADVANCED) {
      return renderAdvancedHeader();
    } else if (tab === Tabs.PREMIUM_SUPPORT) {
      return renderPremiumSupportHeader();
    }
    return renderSignupHeader();
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  const onRoleOptionChange = (field, value) => {
    const showRole = SessionUtils.somethingElseRole(value);
    setRole({ ...role, value, showRole });
  };

  const handleFirstNameChange = (field, value) => {
    setFirstName({ value, errorMessage: "" });
  };

  const handleLastNameChange = (field, value) => {
    setLastName({ value, errorMessage: "" });
  };

  const handleRoleChange = (field, value) => {
    setRole({ ...role, errorMessage: "", roleValue: value });
  };

  const renderRecaptcha = () => {
    if (recaptchaFailed) {
      return (
        <Recaptcha
          siteKey={recaptchaSiteKey}
          onChange={onRecaptchaChange}
          failedTime={recaptchaFailed}
        />
      );
    }
  };

  const renderTerms = () => {
    if (tab === Tabs.SIGNUP) {
      return (
        <CheckBox
          name="terms_and_privacy"
          label={
            <div className="label-color">
              {CommonUtils.i18n("session.signup.terms_and_privacy.accept")}
              <Link
                href={CommonUtils.i18n("urls.privacy_policy")}
                className="href_color"
                target="_blank"
              >
                {CommonUtils.i18n("session.signup.terms_and_privacy.privacy")}
              </Link>
              {CommonUtils.i18n("session.signup.terms_and_privacy.and_the")}
              <Link
                href={CommonUtils.i18n("urls.supplier_terms_and_conditions")}
                className="href_color"
                target="_blank"
              >
                {CommonUtils.i18n("session.signup.terms_and_privacy.terms")}
              </Link>
            </div>
          }
          onChange={handleTermsChange}
          showMessages={!!terms.errorMessage}
          errorMessage={terms.errorMessage}
          value={terms.value}
          className="formField termsAndPrivacy nopadding"
          inputSelector="s-termsAndPrivacy"
        />
      );
    }
  };

  const renderCampaignInviteFields = () => {
    if (campaign) {
      return (
        <div className="s-campaignInvite">
          <div className="groupFields fieldWidth">
            <SelectField
              name="roleOption"
              label={CommonUtils.i18n("session.signup.role")}
              value={role.value}
              onChange={onRoleOptionChange}
              selectOptions={SessionUtils.roleOptions}
              requiredField={true}
              showMessages={true}
              errorMessage={role.errorMessage}
              inputSelector="s-roleOption nopadding"
            />
            {role.showRole && (
              <TextField
                id="role"
                name="role"
                requiredField={true}
                showMessages={true}
                value={role.roleValue}
                errorMessage={role.roleErrorMessage}
                onInput={handleRoleChange}
                className="otherRole formField"
                inputSelector="s-role"
              />
            )}
          </div>
        </div>
      );
    }
  };

  const renderForwardInvitation = () => {
    if (enableForwardInvite) {
      return (
        <Button
          buttonText={CommonUtils.i18n("session.forward_invitation_message")}
          className="buttonAsLink btn_highlightcolor s-forward"
          onClick={handleForwardInvitation}
        />
      );
    }
  };

  const renderLoginOption = () => {
    if (tab == Tabs.SIGNUP) {
      return (
        <div className="mt-2 flexDisplay">
          <span className="info_color">
            {CommonUtils.i18n("session.signup.already_have_account")}
          </span>
          <ReachLink to={Routes.LOGIN_PATH}>
            <Button
              buttonText={CommonUtils.i18n("session.login.label")}
              className="buttonAsLink btn_highlightcolor formField"
              onClick={handleLogin}
            />
          </ReachLink>
        </div>
      );
    }
  };

  const renderPasswordFields = () => {
    if (tab == Tabs.SIGNUP) {
      return (
        <div>
          <div className="groupFields">
            <div className="passwordEyeGroup">
              <TextField
                id="password"
                name="password"
                type={passwordEye.type}
                label={CommonUtils.i18n("session.signup.password")}
                requiredField={true}
                showMessages={true}
                value={password.value}
                errorMessage={password.errorMessage}
                onInput={handlePasswordChange}
                className="formField"
                inputSelector="s-password"
              />
              <i
                className={classNames(
                  "fa",
                  "fa-fw",
                  passwordEye.class,
                  "passwordEye"
                )}
                onClick={handleEyeClick}
              />
            </div>
            <div className="passwordEyeGroup">
              <TextField
                id="confirm_password"
                name="confirm_password"
                type={passwordEye.type}
                label={CommonUtils.i18n("session.signup.confirm_password")}
                requiredField={true}
                showMessages={true}
                value={confirmPassword.value}
                errorMessage={confirmPassword.errorMessage}
                onInput={handleConfirmPasswordChange}
                className="confirmPassword formField"
                inputSelector="s-confirmPassword"
              />
              <i
                className={classNames(
                  "fa",
                  "fa-fw",
                  passwordEye.class,
                  "passwordEye"
                )}
                onClick={handleEyeClick}
              />
            </div>
          </div>
          <div
            className={classNames("passwordHint", {
              "passwordHint-marginTop":
                password.errorMessage || confirmPassword.errorMessage,
            })}
          >
            <span>{CommonUtils.i18n("session.signup.password_hint")}</span>
          </div>
        </div>
      );
    }
  };

  const handleTaxRegistration = (
    taxCountryId: number,
    taxNumber: string,
    skipTaxNumber: boolean,
    skipTaxReason: string,
    index?: number
  ) => {
    setTaxRegisration({
      tax_country_id: taxCountryId,
      tax_number: taxNumber,
      skip_tax_number: skipTaxNumber,
      skip_tax_reason: skipTaxReason,
    });
  };

  const renderTaxRegistrations = () => {
    if (tab == Tabs.SIGNUP) {
      return (
        <TaxRegistrationsEdit
          countries={countries}
          taxRegistrations={[taxRegistration]}
          allowMultipleTax={false}
          callBackfn={handleTaxRegistration}
          taxErrors={taxRegErrors}
          requiredFields={false}
          page={Tabs.SIGNUP}
        />
      );
    }
  };

  const renderPhoneField = () => {
    if (isUngatedSubscriptionsTab(tab)) {
      return (
        <div>
          <TextField
            id="phone_number"
            name="phone_number"
            className="formField"
            label={CommonUtils.i18n(
              "session.verified_onborading_basic_details.phone_number"
            )}
            value={phoneNumber.value}
            requiredField={true}
            inputSelector="s-phoneNumber"
            showMessages={true}
            errorMessage={phoneNumber.errorMessage}
            onInput={handlephoneNumberChange}
          />
        </div>
      );
    }
  };

  const primaryButtonText = () => {
    return isUngatedSubscriptionsTab(tab)
      ? CommonUtils.i18n("session.verified_onborading_basic_details.continue")
      : CommonUtils.i18n("session.signup.create_account");
  };

  return (
    <div id="signup">
      <div className="row">
        <div className="col-sm-3 hide_on_mobile">
          {campaign && customer?.logo && (
            <Icon src={customer.logo} className="s-logo" />
          )}
        </div>
        <div className="col-sm-6">{renderHeader()}</div>
        <div className="col-sm-3 hide_on_mobile"></div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-sm-offset-3">
          <div className="content">
            <form onSubmit={handleSubmit}>
              <TextField
                id="business_name"
                name="business_name"
                label={CommonUtils.i18n("session.signup.business_name")}
                requiredField={true}
                showMessages={true}
                hint={CommonUtils.i18n("session.signup.business_hint")}
                value={businessName.value}
                errorMessage={businessName.errorMessage}
                onInput={handleBusinessNameChange}
                className="formField"
                inputSelector="s-businessName"
                disabled={businessName.disabled}
              />

              <TextField
                id="email"
                name="email"
                label={CommonUtils.i18n("session.signup.email")}
                requiredField={true}
                showMessages={true}
                value={email.value}
                errorMessage={email.errorMessage}
                onInput={handleEmailChange}
                className="formField"
                inputSelector="s-email"
                disabled={email.disabled}
              />
              <div
                className={classNames("groupFields", {
                  flexColumn: isUngatedSubscriptionsTab(tab),
                  fieldWidth: tab === Tabs.SIGNUP,
                })}
              >
                <TextField
                  id="firstName"
                  name="firstName"
                  label={CommonUtils.i18n("session.signup.first_name")}
                  requiredField={true}
                  showMessages={true}
                  value={firstName.value}
                  errorMessage={firstName.errorMessage}
                  onInput={handleFirstNameChange}
                  className="formField"
                  inputSelector="s-firstName"
                />
                <TextField
                  id="lastName"
                  name="lastName"
                  label={CommonUtils.i18n("session.signup.last_name")}
                  requiredField={true}
                  showMessages={true}
                  value={lastName.value}
                  errorMessage={lastName.errorMessage}
                  onInput={handleLastNameChange}
                  className="formField"
                  inputSelector="s-lastName"
                />
              </div>
              {renderCampaignInviteFields()}
              {renderPasswordFields()}
              {renderTaxRegistrations()}
              {renderPhoneField()}
              {renderTerms()}
              {renderRecaptcha()}
              <div className="button-group">
                <Button
                  type="submit"
                  buttonText={primaryButtonText()}
                  className="mt-2 roundedButton session_btn blue btn_margin formField s-signUp actionButton nopadding"
                  disabled={disableSignup}
                />
                {renderLoginOption()}
                {renderForwardInvitation()}
              </div>
            </form>
          </div>
        </div>
        <div className="col-sm-3 hide_on_mobile"></div>
      </div>
    </div>
  );
};

export default Signup;
