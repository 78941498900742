import { ModalActionCreators, AxiosAPIUtils } from "coupa-common-js";
import {
  ModalTypes,
  ActionTypes,
  Routes,
  ActionTypesVerification,
} from "../utils/AppConstants";
import CommonUtils from "coupa-common-js/core/utils/CommonUtils";
import { getEndpointForOffering } from "../utils/SupplierVerificationAppUtils";

export function openSupplierVerificationModal(modalContextData = null) {
  return (dispatch) => {
    dispatch(
      ModalActionCreators.openModal(
        ModalTypes.SUPPLIER_VERIFICATION,
        modalContextData
      )
    );
  };
}

// this function will be called when starting Supplier verification app,
// which will give the necessary context for the app
// which modal to display, and other details
export function loadSupplierVerification() {
  return (dispatch) => {
    return AxiosAPIUtils.get(Routes.FETCH_STATUS)
      .then((response) => {
        dispatch(fetchSupplierVerificationSuccessAction(response));
      })
      .catch((response) => {
        dispatch(fetchSupplierVerificationErrorAction(response));
      });
  };
}

export function fetchSupplierInformation(offering) {
  return (dispatch) => {
    return AxiosAPIUtils.get(
      getEndpointForOffering(ActionTypes.SUPPLIER_INFORMATION, offering)
    )
      .then((response) => {
        dispatch(fetchSupplierInformationSuccessAction(response));
      })
      .catch((response) => {
        dispatch(fetchSupplierInformationErrorAction(response));
      });
  };
}

export function saveContactInfo(saveData) {
  return (dispatch) => {
    return AxiosAPIUtils.post(
      getEndpointForOffering(ActionTypes.SAVE_CONTACT_INFO, saveData.offering),
      { data: saveData }
    )
      .then((response) => {
        dispatch(saveContactInfoSuccessAction(response));
      })
      .catch((response) => {
        dispatch(saveContactInfoErrorAction(response));
      });
  };
}

export function saveDocumentInfo(saveData) {
  return (dispatch) => {
    return AxiosAPIUtils.post(Routes.SAVE_DOCUMENT_INFO, saveData, {
      checkMultiPart: true,
    })
      .then((response) => {
        if (response.redirectTo) dispatch(signupRedirect(response));
        else dispatch(saveDocumentInfoSuccessAction(response));
      })
      .catch((response) => {
        dispatch(saveDocumentInfoErrorAction(response));
      });
  };
}

export function createStripeIntent(data) {
  return (dispatch) => {
    return AxiosAPIUtils.get(Routes.CREATE_STRIPE_INTENT, {
      data: data,
    })
      .then((response) => {
        dispatch(createStripeIntentSuccessAction(response));
      })
      .catch((response) => {
        dispatch(createStripeIntentErrorAction(response));
      });
  };
}

export function clearPaymentIntent() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_PAYMENT_INTENT,
    });
  };
}

export function signupRedirect(response) {
  window.location = response.redirectTo;
}

export function processPaymentCompleted(saveData) {
  return (dispatch) => {
    AxiosAPIUtils.post(
      getEndpointForOffering(ActionTypes.SAVE_PAYMENT_INFO, saveData.offering),
      saveData
    )
      .then((response) => {
        dispatch(savePaymentInfoSuccessAction(response));
        if (response.redirectTo) {
          signupRedirect(response);
        } else if (response.page == "login") {
          dispatch({ type: ActionTypesVerification.LOAD_LOGIN });
        }
      })
      .catch((response) => {
        dispatch(savePaymentInfoErrorAction(response));
      });
  };
}

export function processSetupCompleted(saveData) {
  return (dispatch) => {
    AxiosAPIUtils.post(
      getEndpointForOffering(ActionTypes.ACTIVATE_TRIAL, saveData.offering),
      saveData
    )
      .then((response) => {
        dispatch(savePaymentInfoSuccessAction(response));
        signupRedirect(response);
      })
      .catch((response) => {
        dispatch(savePaymentInfoErrorAction(response));
      });
  };
}

export function savePaymentErrors(data, errorMessage) {
  return (dispatch) => {
    AxiosAPIUtils.post(
      getEndpointForOffering(ActionTypes.SAVE_PAYMENT_ERRORS, data.offering),
      {
        ...data,
        error: errorMessage,
      }
    ).then((response) => {
      dispatch(savePaymentErrorsSuccessAction(response));
    });
  };
}

export function saveSubscriberAddress(data) {
  return (dispatch) => {
    AxiosAPIUtils.post(Routes.SAVE_SUBSCRIBER_ADDRESS, data);
  };
}

export function applyPromoCode(data, promoCode, email, offering) {
  return (dispatch) => {
    AxiosAPIUtils.post(Routes.APPLY_PROMO_CODE, {
      ...data,
      promo_code: promoCode,
      email: email,
      offering: offering,
    })
      .then((response) => {
        dispatch(applyPromoCodeSuccessAction(response));
      })
      .catch((response) => {
        dispatch(applyPromoCodeErrorAction(response));
      });
  };
}

export function removePromoCode(data, email, offering) {
  return (dispatch) => {
    AxiosAPIUtils.post(Routes.REMOVE_PROMO_CODE, {
      ...data,
      promo_code: data.coupon_applied,
      email: email,
      offering: offering,
    })
      .then((response) => {
        dispatch(removePromoCodeSuccessAction(response));
      })
      .catch((response) => {
        dispatch(removePromoCodeErrorAction(response));
      });
  };
}

export function clearErrors() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_ERRORS,
    });
  };
}

export function reloadPage() {
  window.location.reload();
}

export function fetchSupplierVerificationSuccessAction(response) {
  return {
    type: ActionTypes.FETCH_STATUS_SUCCESS,
    data: response,
  };
}

export function fetchSupplierVerificationErrorAction(response) {
  return {
    type: ActionTypes.FETCH_STATUS_ERROR,
    error: new Error(CommonUtils.i18n("coupa_verified.errors.application")),
  };
}

export function fetchSupplierInformationSuccessAction(response) {
  return {
    type: ActionTypes.FETCH_SUPPLIER_INFORMATION_SUCCESS,
    data: response,
  };
}

export function fetchSupplierInformationErrorAction(response) {
  return {
    type: ActionTypes.FETCH_SUPPLIER_INFORMATION_ERROR,
    error: new Error(
      response.responseJSON?.error ||
        CommonUtils.i18n("coupa_verified.errors.application")
    ),
  };
}

export function saveContactInfoSuccessAction(response) {
  return {
    type: ActionTypes.SAVE_CONTACT_INFO_SUCCESS,
    data: response,
  };
}

export function saveContactInfoErrorAction(response) {
  return {
    type: ActionTypes.SAVE_CONTACT_INFO_ERROR,
    error: new Error(CommonUtils.i18n("coupa_verified.errors.save")),
  };
}

export function saveDocumentInfoSuccessAction(response) {
  return {
    type: ActionTypes.SAVE_DOCUMENT_INFO_SUCCESS,
    data: response,
  };
}

export function saveDocumentInfoErrorAction(response) {
  return {
    type: ActionTypes.SAVE_DOCUMENT_INFO_ERROR,
    error: new Error(CommonUtils.i18n("coupa_verified.errors.save")),
  };
}

export function savePaymentInfoSuccessAction(response) {
  return {
    type: ActionTypes.SAVE_PAYMENT_INFO_SUCCESS,
    data: response,
  };
}

export function savePaymentInfoErrorAction() {
  return {
    type: ActionTypes.SAVE_PAYMENT_INFO_ERROR,
    error: new Error(CommonUtils.i18n("coupa_verified.errors.save")),
  };
}

export function savePaymentErrorsSuccessAction(response) {
  return {
    type: ActionTypes.SAVE_PAYMENT_ERRORS_SUCCESS,
    data: response,
  };
}

export function createStripeIntentSuccessAction(response) {
  return {
    type: ActionTypes.CREATE_STRIPE_INTENT_SUCCESS,
    data: response,
  };
}

export function createStripeIntentErrorAction(response) {
  return {
    type: ActionTypes.CREATE_STRIPE_INTENT_ERROR,
    error: new Error(
      response.responseJSON?.error ||
        CommonUtils.i18n("coupa_verified.errors.payment_initialization")
    ),
  };
}

export function applyPromoCodeSuccessAction(response) {
  return {
    type: ActionTypes.APPLY_PROMO_CODE_SUCCESS,
    data: {
      ...response,
      couponError: null,
      couponErrorMessage: null,
    },
  };
}

export function applyPromoCodeErrorAction(response) {
  return {
    type: ActionTypes.APPLY_PROMO_CODE_ERROR,
    data: {
      couponError: response.responseJSON.coupon_error,
      couponErrorMessage: response.responseJSON.coupon_error_message,
    },
    error: new Error(
      response.responseJSON?.error ||
        CommonUtils.i18n("coupa_verified.errors.coupon_invalid")
    ),
  };
}

export function removePromoCodeSuccessAction(response) {
  return {
    type: ActionTypes.REMOVE_PROMO_CODE_SUCCESS,
    data: response,
  };
}

export function removePromoCodeErrorAction(response) {
  return {
    type: ActionTypes.REMOVE_PROMO_CODE_ERROR,
    error: new Error(
      response.responseJSON?.error ||
        CommonUtils.i18n("coupa_verified.errors.remove_coupon")
    ),
  };
}
