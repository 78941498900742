import React, { useState, useEffect } from "react";
import { CommonUtils, Button, TextField } from "coupa-common-js";
import { ActionTypes, Tabs, Routes } from "../../utils/constants";
import Recaptcha from "../../../common/components/Recaptcha";
import { useDispatch, useSelector } from "react-redux";
import * as OtpActionCreator from "../../actions/OtpActionCreator";
import * as SignupActionCreator from "../../actions/SignupActionCreator";
import SupplierCommonUtils from "../../../common/utils/SupplierCommonUtils";

const OtpVerification = () => {
  const dispatch = useDispatch();

  const {
    recaptchaFailed,
    recaptchaSiteKey,
    signupObj,
    hasError,
    message,
    redirectTo,
    visitorId,
  } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });

  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [disableVerifyEmail, setDisableVerifyEmail] = useState(false);

  useEffect(() => {
    if (!redirectTo) setDisableVerifyEmail(false);
  }, [hasError, message]);

  const renderHeader = () => {
    return (
      <div className="header">
        <div className="mainHeader">
          {CommonUtils.i18n("session.otp_verification.email_verification")}
        </div>
        <div className="subHeader">
          {CommonUtils.i18n("session.otp_verification.passcode_sub_header", {
            email: signupObj.user.email,
          })}
        </div>
      </div>
    );
  };

  const handleBack = () => {
    dispatch({
      type: ActionTypes.LOAD_SIGNUP,
    });
  };

  const handleResendOtp = () => {
    dispatch(
      OtpActionCreator.sendOTP(
        {
          ...signupObj,
          "g-recaptcha-response": recaptchaToken,
        },
        Tabs.OTP_VERIFICATION
      )
    );
  };

  const isValidOtp = () => {
    const otp_values = Object.values(otp).join("");
    if (otp_values.length < 6) {
      SupplierCommonUtils.showErrorNotification(
        CommonUtils.i18n("session.otp_incomplete")
      );
      return false;
    }
    if (recaptchaFailed && !recaptchaToken) {
      SupplierCommonUtils.showErrorNotification(
        CommonUtils.i18n("session.recaptcha_check")
      );
      return false;
    }
    return true;
  };

  const handleVerifyEmail = (e) => {
    e.preventDefault();
    if (isValidOtp()) {
      setDisableVerifyEmail(true);
      const otpValue = Object.values(otp).join("");
      dispatch(
        SignupActionCreator.createAccount({
          ...signupObj,
          otp: otpValue,
          "g-recaptcha-response": recaptchaToken,
          visitor_id: visitorId,
        })
      );
    }
  };

  const handleOtpKeyPress = (key, field, value, event) => {
    if ((key < 48 || key > 57) && key !== 13) event.preventDefault();
  };

  const handleOtpChange = (field, value) => {
    setOtp({ ...otp, [field]: value });
  };

  const inputfocus = (element) => {
    if (element.key < 48 || element.key > 57) {
      const next = element.target.tabIndex;
      if (next < 6) {
        element.target.form.elements[next].focus();
      }
    } else if (element.key === "Backspace") {
      const previous = element.target.tabIndex - 2;
      if (previous >= 0) {
        element.target.form.elements[previous].focus();
        element.target.form.elements[previous].select();
      }
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  const renderRecaptcha = () => {
    if (recaptchaFailed) {
      return (
        <Recaptcha
          siteKey={recaptchaSiteKey}
          onChange={onRecaptchaChange}
          failedTime={recaptchaFailed}
        />
      );
    }
  };

  const isValidOtpNumber = (value) => {
    if (value?.length === 6 && !isNaN(value)) return true;
    else return false;
  };

  const handlePaste = (event) => {
    const pastedText = (event.clipboardData || window.clipboardData).getData(
      "text/plain"
    );
    if (!isValidOtpNumber(pastedText)) return;
    const map = {};
    for (let index = 1; index <= 6; index++) {
      map[`otp${index}`] = pastedText[index - 1];
    }
    setOtp(map);
  };

  const renderInput = () => {
    const elements = [];
    for (let index = 1; index <= 6; index++) {
      const name = `otp${index}`;
      elements.push(
        <TextField
          key={name}
          name={name}
          autoComplete="off"
          inputSelector={`otpInput s-${name}`}
          value={otp[name]}
          onKeyPress={handleOtpKeyPress}
          onInput={handleOtpChange}
          maxLength="1"
          inputProps={{
            tabIndex: index,
            onKeyUp: (e) => inputfocus(e),
            onPaste: (e) => handlePaste(e),
          }}
        />
      );
    }
    return elements;
  };

  return (
    <div id="otpVerification">
      <div className="row">
        <div className="col navigation">
          <Button
            buttonText={CommonUtils.i18n("session.back")}
            className="buttonAsLink back s-back"
            onClick={handleBack}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 hidden-sm"></div>
        <div className="col-md-8">
          {renderHeader()}
          <div className="content">
            <form onSubmit={handleVerifyEmail}>
              <div className="otpContainer">{renderInput()}</div>
              <div className="groupFields resendPasscode">
                <span>
                  {CommonUtils.i18n("session.otp_verification.passcode_resend")}
                </span>
                <Button
                  id="passcode_resend"
                  buttonText={CommonUtils.i18n(
                    "session.otp_verification.passcode_resend_button"
                  )}
                  className="buttonAsLink s-resendCode"
                  onClick={handleResendOtp}
                />
              </div>
              <div className="button-group">
                {renderRecaptcha()}
                <Button
                  type="submit"
                  buttonText={CommonUtils.i18n("session.next")}
                  className="roundedButton session_btn blue btn_margin actionButton s-next"
                  disabled={disableVerifyEmail}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-2 hidden-sm"></div>
      </div>
    </div>
  );
};

export default OtpVerification;
