import React, { useState, useEffect } from "react";
import { CommonUtils, Button, TextField } from "coupa-common-js";
import sanitizeContent from "coupa-common-js/core/utils/SanitizeUtils";
import { ActionTypes } from "../../utils/constants";
import Recaptcha from "../../../common/components/Recaptcha";
import { useDispatch, useSelector } from "react-redux";
import * as SessionUtils from "../../utils/SessionUtils";
import ReactTooltip from "react-tooltip";
import SupplierCommonUtils from "../../../common/utils/SupplierCommonUtils";
import * as ForwardInvitationActionCreator from "../../actions/ForwardInvitationActionCreator";

const ForwardInvitation = () => {
  const dispatch = useDispatch();

  const {
    recaptchaFailed,
    recaptchaSiteKey,
    forwardMessage,
    invite_email,
    invite_code,
    hasError,
    message,
    visitorId,
  } = useSelector(({ SessionReducer }) => {
    return { ...SessionReducer };
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [username, setUserName] = useState({
    value: "",
    errorMessage: "",
  });
  const [disableForward, setDisableForward] = useState(false);

  useEffect(() => {
    setDisableForward(false);
  }, [hasError, message]);

  const handleUserNameChange = (field, value) => {
    setUserName({ value: value, errorMessage: "" });
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  const handleBack = () => {
    dispatch({
      type: ActionTypes.LOAD_SIGNUP_WITHOUT_RESET,
    });
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <div className="mainHeader">
          {CommonUtils.i18n("session.forward_invitation.title")}
        </div>
        <div className="subHeader">
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeContent(forwardMessage),
            }}
          />
        </div>
      </div>
    );
  };

  const renderRecaptcha = () => {
    if (recaptchaFailed) {
      return (
        <Recaptcha
          siteKey={recaptchaSiteKey}
          onChange={onRecaptchaChange}
          failedTime={recaptchaFailed}
        />
      );
    }
  };

  const isValidForwardInvitation = () => {
    let full_email = username.value + "@" + invite_email.split("@")[1];
    if (!username.value) {
      setUserName({
        errorMessage: CommonUtils.i18n("session.email_empty"),
      });
      return false;
    }
    if (!SessionUtils.isValidEmail(full_email)) {
      setUserName({
        value: username.value,
        errorMessage: CommonUtils.i18n("session.email_invalid"),
      });
      return false;
    }
    if (recaptchaFailed && !recaptchaToken) {
      SupplierCommonUtils.showErrorNotification(
        CommonUtils.i18n("session.recaptcha_check")
      );
      return false;
    }
    return true;
  };

  const handleForwardInvitation = (e) => {
    e.preventDefault();
    if (isValidForwardInvitation()) {
      setDisableForward(true);
      dispatch(
        ForwardInvitationActionCreator.forwardInvitation({
          referral_email: username.value + "@" + invite_email.split("@")[1],
          invite_code,
          "g-recaptcha-response": recaptchaToken,
          visitor_id: visitorId,
        })
      );
    }
  };

  return (
    <div id="forwardInvitation">
      <div className="row">
        <div className="col navigation">
          <Button
            buttonText={CommonUtils.i18n("session.back")}
            className="buttonAsLink back s-back"
            onClick={handleBack}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3 hide_on_mobile"></div>
        <div className="col-sm-6">
          {renderHeader()}
          <div className="content">
            <label className="label_left">
              <span className="fieldRequired">*</span>{" "}
              {CommonUtils.i18n("session.signup.email")}
            </label>
            <form onSubmit={handleForwardInvitation}>
              <div className="forwardInvite">
                <TextField
                  id="emailSubString"
                  name="emailSubString"
                  type="text"
                  onInput={handleUserNameChange}
                  value={username.value}
                  errorMessage={username.errorMessage}
                  className="nopadding emailDomain"
                  inputSelector="s-emailSubString"
                  requiredField={true}
                  showMessages={true}
                />
                <TextField
                  className="nopadding emailDomain"
                  inputSelector="s-emailDomain"
                  disabled={true}
                  value={"@" + invite_email.split("@")[1]}
                />
              </div>
              <div className="recaptchaForwardInvitation">
                {renderRecaptcha()}
              </div>
              <Button
                type="submit"
                buttonText={CommonUtils.i18n(
                  "session.forward_invitation.button"
                )}
                className="roundedButton blue forwardButton s-forward"
                disabled={disableForward}
              />
            </form>
            <div className="mt-4 center issueWithSignup">
              <p
                data-tip={CommonUtils.i18n(
                  "session.issue_with_signup_description"
                )}
              >
                {CommonUtils.i18n("session.issue_with_signup_message")}
              </p>
              <ReactTooltip
                effect="solid"
                place="bottom"
                border={true}
                className="reactTooltip s-reactTooltip"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 hide_on_mobile"></div>
      </div>
    </div>
  );
};

export default ForwardInvitation;
