import { Address } from "../interfaces";

export function join(arr: string[], separator = " ") {
  return arr.filter(Boolean).join(separator);
}

export function oneLine(address?: Address) {
  if (!address) {
    return "";
  }

  return join(
    [
      address.street1,
      address.street2,
      address.street3,
      address.street4,
      address.city,
      address.translated_state,
      join([
        address.postal_code,
        address.country && address.country.translated_name,
      ]),
    ],
    ", "
  );
}

export function mapsUrl(address?: Address) {
  const oneLineAddress = oneLine(address);

  const query = encodeURIComponent(oneLineAddress);

  return `https://www.google.com/maps/search/?api=1&query=${query}`;
}
