import React, { useState, useEffect } from "react";
import { Button, CheckBox, CommonUtils, Icon } from "coupa-common-js";
import TaxReg from "./TaxReg";
import { TaxRegistration } from "reactAppRoute/profile/interfaces";
import "./TaxRegistrationsEdit.scss";
import { objectsEqual } from "../../../utils/TaxRegistrationUtils";

export interface TaxRegistrationsEditProps {
  countries: {
    label: string;
    value: number;
    code: string;
  }[];
  callBackfn: (
    taxCountryId: number,
    taxNumber: string,
    skipTaxNumber: boolean,
    skipTaxReason: string,
    index?: number
  ) => void;
  taxRegistrations: TaxRegistration;
  allowMultipleTax: boolean;
  taxErrors?: any;
  requiredFields: boolean;
  page?: string;
  handleTaxIdAdd?: () => void;
  handleTaxIdRemove?: (index: number) => void;
}

const TaxRegistrationsEdit = ({
  countries,
  taxRegistrations,
  allowMultipleTax,
  taxErrors,
  requiredFields,
  page,
  callBackfn,
  handleTaxIdAdd = () => {},
  handleTaxIdRemove = () => {},
}: TaxRegistrationsEditProps) => {
  const [skipTaxNumber, setSkipTaxNumber] = useState(false);
  const [skipTaxReason, setSkipTaxReason] = useState("");
  const [taxRegistration, setTaxRegistration] = useState([]);
  const [showSkipTax, setShowSkipTax] = useState(false);

  useEffect(() => {
    if (!objectsEqual(taxRegistrations, taxRegistration)) {
      setTaxRegistration(taxRegistrations?.map((tr) => ({ ...tr })));
    }
  }, [taxRegistrations]);

  useEffect(() => {
    const showSkipTax =
      taxRegistration?.filter((taxReg) => !taxReg._destroy)?.length === 1;
    setShowSkipTax(showSkipTax);
    if (showSkipTax && taxRegistration[0].skip_tax_number) {
      setSkipTaxNumber(true);
      setSkipTaxReason(taxRegistration[0].skip_tax_reason);
    }
  }, [taxRegistration]);

  const handleNoTaxReason = (evt) => {
    const value = evt.target.value;
    setSkipTaxReason(value);
    callBackfn(null, null, true, value, 0);
  };

  const addTaxId = () => {
    setShowSkipTax(false);
    handleTaxIdAdd();
  };

  const removeTaxId = (index) => {
    handleTaxIdRemove(index);
  };

  const handleTaxIdChange = (countryId, taxNumber, index) => {
    callBackfn(countryId, taxNumber, skipTaxNumber, "", index);
  };

  const handleSkipTaxChange = () => {
    setSkipTaxNumber(!skipTaxNumber);
    callBackfn(null, null, !skipTaxNumber, "", 0);
    setTaxRegistration((taxRegistration) => {
      taxRegistration[0].tax_number = null;
      taxRegistration[0].tax_country_id = null;
      return [...taxRegistration];
    });
  };

  const renderTaxRegistrations = (
    <>
      {taxRegistration?.map(
        (taxReg, index) =>
          !taxReg._destroy && (
            <TaxReg
              key={index + "taxReg"}
              value={taxReg}
              countries={countries}
              index={index}
              showDeleteIcon={allowMultipleTax && !showSkipTax}
              disabled={skipTaxNumber}
              handleOnChange={handleTaxIdChange}
              removeTaxId={removeTaxId}
              taxError={taxErrors && taxErrors[index]}
              requiredFields={requiredFields}
              page={page}
            />
          )
      )}
    </>
  );

  let skiptaxReasonErrorLabel = "";
  if (taxErrors?.[0]?.errors?.skip_tax_reason?.length) {
    const errorMessages = taxErrors[0].errors.skip_tax_reason;
    skiptaxReasonErrorLabel = errorMessages.join("\n");
  }

  const renderTaxOptionsRow = (
    <div className="bs4-row bs4-col-11 bs4-col-md-11 bs4-col-lg-11 mt-2 taxOptionsRow">
      <div className="mt-2">
        {showSkipTax && (
          <CheckBox
            checked={skipTaxNumber}
            label={CommonUtils.i18n("defaults.tax_registrations.no_tax_id")}
            name="noTaxIdCheck"
            className="s-skipTaxNumber"
            onChange={handleSkipTaxChange}
            inputSelector="noTaxIdCheck"
          />
        )}
      </div>

      {allowMultipleTax && !skipTaxNumber && (
        <div className="mt-2 additionalTaxId">
          <Button
            type="button"
            className="bs4-btn-link addTaxIdButton buttonAsLink s-addTaxIdButton"
            onClick={addTaxId}
          >
            <Icon className="sprite-add icon_button" />
            {CommonUtils.i18n(
              "defaults.tax_registrations.additional_tax_registration"
            )}
          </Button>
        </div>
      )}
    </div>
  );

  const renderSkipTaxReason = (
    <>
      {skipTaxNumber && (
        <div className="bs4-row bs4-col-md-8 bs4-col-lg-8 skipTaxReason mt-3">
          <div data-testid="skipTaxReason">
            <label className="label label-default" htmlFor="noTaxReason">
              <span className="taxFieldsRequired" aria-hidden="true">
                *
              </span>
              {CommonUtils.i18n("defaults.tax_registrations.no_tax_reason")}
            </label>
            <input
              name="noTaxReason"
              id="noTaxReason"
              className="bs4-form-control mt-2"
              type="text"
              required={requiredFields}
              onChange={(evt) => handleNoTaxReason(evt)}
              value={skipTaxReason}
              placeholder={CommonUtils.i18n(
                "defaults.tax_registrations.no_tax_placeholder"
              )}
            />
            {skiptaxReasonErrorLabel && (
              <div className="taxError mt-2">{skiptaxReasonErrorLabel}</div>
            )}
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="taxRegistrationsEdit">
      {renderTaxRegistrations}
      {renderTaxOptionsRow}
      {renderSkipTaxReason}
    </div>
  );
};

export default TaxRegistrationsEdit;
