import React, { useState, useEffect } from "react";
import {
  AutoComplete,
  SelectField,
  CommonUtils,
  TextField,
  IconButton,
} from "coupa-common-js";
import {
  AutoCompAddress,
  MIN_SEARCH_LEN,
  ADDR1,
  ADDR2,
  ADDR3,
  ADDR4,
} from "../utils/AppConstants";
import SupplierCommonUtils from "../utils/SupplierCommonUtils";
import Immutable from "immutable";

const AutoCompleteAddress = ({
  address,
  errors,
  disabled,
  disableCountry = true,
  countryList,
  onAddressChange,
  purpose,
  required = true,
  resetActiveLine = false,
}) => {
  const initActiveLine = () => {
    if (
      CommonUtils.isEmpty(address.street2) &&
      CommonUtils.isEmpty(address.street3) &&
      CommonUtils.isEmpty(address.street4)
    )
      return ADDR1;
    if (
      CommonUtils.isEmpty(address.street3) &&
      CommonUtils.isEmpty(address.street4)
    )
      return ADDR2;
    if (CommonUtils.isEmpty(address.street4)) return ADDR3;
    return ADDR4;
  };

  const [activeLine, setActiveLine] = useState(initActiveLine());

  useEffect(() => {
    if (resetActiveLine) {
      setActiveLine(ADDR1);
    }
  }, [resetActiveLine]);

  const handleAddressChange = (field, value) => {
    if (value instanceof Immutable.Map) {
      SupplierCommonUtils.getPlaceDetails(value.get("id"), { purpose: purpose })
        .then((result) => {
          onAddressChange(field, value, {
            street1: result.street1,
            city: result.city,
            state: result.state,
            country_id: address.country_id,
            postal_code: result.postal_code,
          });
        })
        .catch((_) => {});
    } else onAddressChange(field, value);
  };

  const onIconAdd = (addrLine) => {
    switch (addrLine) {
      case ADDR1:
        setActiveLine(ADDR2);
        break;
      case ADDR2:
        setActiveLine(ADDR3);
        break;
      case ADDR3:
        setActiveLine(ADDR4);
        break;
      default:
        return null;
    }
  };
  const onIconDelete = (addrLine, onAddressChange) => {
    switch (addrLine) {
      case ADDR2:
        setActiveLine(ADDR1);
        onAddressChange("street2", "");
        break;
      case ADDR3:
        setActiveLine(ADDR2);
        onAddressChange("street3", "");
        break;
      case ADDR4:
        setActiveLine(ADDR3);
        onAddressChange("street4", "");
        break;
      default:
        return null;
    }
  };

  const isVisibleLine = (currentLine) => {
    switch (activeLine) {
      case ADDR1:
        return currentLine === ADDR1;
      case ADDR2:
        return [ADDR1, ADDR2].includes(currentLine);
      case ADDR3:
        return [ADDR1, ADDR2, ADDR3].includes(currentLine);
      case ADDR4:
        return [ADDR1, ADDR2, ADDR3, ADDR4].includes(currentLine);
      default:
        return false;
    }
  };

  const renderAddIcon = (addrLine) => {
    return (
      <span className="inputDescriptor">
        <IconButton
          className="icon icon_button sprite-add"
          alt={CommonUtils.i18n("defaults.add")}
          title={CommonUtils.i18n("defaults.add")}
          onClick={() => onIconAdd(addrLine)}
        />
      </span>
    );
  };

  const renderDeleteIcon = (addrLine) => {
    return (
      <span className="inputDescriptor">
        <IconButton
          className="icon icon_button sprite-delete_large"
          alt={CommonUtils.i18n("defaults.delete")}
          title={CommonUtils.i18n("defaults.delete")}
          onClick={() => onIconDelete(addrLine, onAddressChange)}
        />
      </span>
    );
  };

  return (
    <div className="s-autoCompleteAddress autoCompleteAddress">
      <div className="card-row">
        <SelectField
          name="country_id"
          className="col-card-md-12 col-card-xs-12"
          label={CommonUtils.i18n("defaults.address.country_code")}
          inputSelector="s-countryId"
          selectOptions={countryList}
          value={address.country_id}
          onChange={onAddressChange}
          requiredField={required}
          showMessages={true}
          errorMessage={errors.country_id}
          disabled={disabled || disableCountry}
          clearable={true}
        />
      </div>
      <div className="card-row rtl_display_block">
        <div className="fieldWrapper s-fieldWrapper addr1">
          <AutoComplete
            name="street1"
            className="col-card-md-12 col-card-xs-12"
            label={CommonUtils.i18n("defaults.address.street1")}
            value={address.street1}
            inputSelector="s-street1Input fullWidth"
            showMessages={true}
            errorMessage={errors.street1}
            onChange={handleAddressChange}
            requiredField={required}
            lookupUrl={AutoCompAddress.LOOKUP_API.replace(
              "{countryId}",
              address.country_id
            )}
            minSearchLen={MIN_SEARCH_LEN}
            hideToggleArrow={true}
            freeForm={true}
            disabled={disabled || CommonUtils.isEmpty(address.country_id)}
            inputProps={{ maxLength: 255 }}
          />
          {!(disabled || CommonUtils.isEmpty(address.country_id)) &&
            activeLine === ADDR1 &&
            renderAddIcon(ADDR1)}
        </div>
      </div>
      {(!CommonUtils.isEmpty(address.street2) || isVisibleLine(ADDR2)) && (
        <div className="card-row">
          <div className="fieldWrapper s-fieldWrapper addr2">
            <TextField
              name="street2"
              className="col-card-md-12 col-card-xs-12"
              label={CommonUtils.i18n("defaults.address.street2")}
              value={address.street2}
              inputSelector="s-street2Input fullWidth"
              showMessages={true}
              errorMessage={errors.street2}
              disabled={disabled || CommonUtils.isEmpty(address.country_id)}
              onInput={onAddressChange}
            />
            {!(disabled || CommonUtils.isEmpty(address.country_id)) &&
              activeLine === ADDR2 &&
              renderAddIcon(ADDR2)}
            {!(disabled || CommonUtils.isEmpty(address.country_id)) &&
              activeLine === ADDR2 &&
              renderDeleteIcon(ADDR2)}
          </div>
        </div>
      )}
      {(!CommonUtils.isEmpty(address.street3) || isVisibleLine(ADDR3)) && (
        <div className="card-row">
          <div className="fieldWrapper s-fieldWrapper addr3">
            <TextField
              name="street3"
              className="col-card-md-12 col-card-xs-12"
              label={CommonUtils.i18n("defaults.address.street3")}
              value={address.street3}
              inputSelector="s-street3Input fullWidth"
              showMessages={true}
              errorMessage={errors.street3}
              disabled={disabled || CommonUtils.isEmpty(address.country_id)}
              onInput={onAddressChange}
            />
            {!(disabled || CommonUtils.isEmpty(address.country_id)) &&
              activeLine === ADDR3 &&
              renderAddIcon(ADDR3)}
            {!(disabled || CommonUtils.isEmpty(address.country_id)) &&
              activeLine == ADDR3 &&
              renderDeleteIcon(ADDR3)}
          </div>
        </div>
      )}
      {(!CommonUtils.isEmpty(address.street4) || isVisibleLine(ADDR4)) && (
        <div className="card-row">
          <div className="fieldWrapper s-fieldWrapper addr4">
            <TextField
              name="street4"
              className="col-card-md-12 col-card-xs-12"
              label={CommonUtils.i18n("defaults.address.street4")}
              value={address.street4}
              inputSelector="s-street4Input fullWidth"
              showMessages={true}
              errorMessage={errors.street4}
              disabled={disabled || CommonUtils.isEmpty(address.country_id)}
              onInput={onAddressChange}
            />
            {!(disabled || CommonUtils.isEmpty(address.country_id)) &&
              activeLine === ADDR4 &&
              renderDeleteIcon(ADDR4)}
          </div>
        </div>
      )}
      <div className="card-row ci-st-po">
        <TextField
          name="city"
          className="col-card-md-4 col-card-xs-12"
          label={CommonUtils.i18n("defaults.address.city")}
          value={address.city}
          inputSelector="s-city"
          showMessages={true}
          errorMessage={errors.city}
          requiredField={required}
          disabled={disabled || CommonUtils.isEmpty(address.street1)}
          onInput={onAddressChange}
        />
        <TextField
          name="state"
          className="col-card-md-4 col-card-xs-6"
          label={CommonUtils.i18n("defaults.address.state")}
          value={address.state}
          inputSelector="s-state"
          showMessages={true}
          errorMessage={errors.state}
          disabled={disabled || CommonUtils.isEmpty(address.street1)}
          onInput={onAddressChange}
        />
        <TextField
          name="postal_code"
          className="col-card-md-4 col-card-xs-6"
          label={CommonUtils.i18n("defaults.address.postal_code")}
          value={address.postal_code}
          inputSelector="s-postalCode"
          showMessages={true}
          errorMessage={errors.postal_code}
          requiredField={required}
          disabled={disabled || CommonUtils.isEmpty(address.street1)}
          onInput={onAddressChange}
        />
      </div>
    </div>
  );
};

export default AutoCompleteAddress;
