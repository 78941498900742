import React, { useState, useEffect, useRef } from "react";
import { redirectTo, navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  CommonUtils,
  AxiosAPIUtils,
  Button,
  AppConstants,
  Link,
  TextField,
} from "coupa-common-js";
import { ActionTypesVerification } from "../../sessions/utils/constants";
import {
  processPaymentCompleted,
  processSetupCompleted,
  savePaymentErrors,
  saveSubscriberAddress,
  applyPromoCode,
  removePromoCode,
} from "../actions/SupplierVerificationActionCreators";
import {
  getHumanizedOfferingName,
  createLocalizedSentence,
} from "../utils/SupplierVerificationAppUtils";
import { Routes, PAGES, OFFERINGS } from "../utils/AppConstants";
import { Icon } from "coupa-common-js/core/Icon";
import coupaVerifiedLarge from "assets/images/coupa_verified_large.svg";
import coupaFlower from "assets/images/coupa_flower.png";
import coupaAdvancedFlower from "assets/images/coupa_advanced_flower.svg";
import blankGif from "assets/images/blank.gif";
import { Spinner } from "coupa-common-js/core/Spinner";
import ReachLink from "supplier-common-ui/dist/components/ReachLink";
import {
  useStripe,
  useElements,
  PaymentElement,
  PaymentRequestButtonElement,
  AddressElement,
} from "@stripe/react-stripe-js";

var elements;

const StripePayment = ({
  actionType,
  nextModal,
  getErrors,
  data,
  basicData,
  offering,
  trial,
  bundle,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const submitRef = useRef();
  const promoCodeRef = useRef();

  const [formErrors, setFormErrors] = useState(null);
  const [subscriberAddress, setSubscriberAddress] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeStatus, setPromoCodeStatus] = useState("blank");

  const translationScope = "coupa_verified.stripe_modal";
  const { first_name = "", last_name = "", invite_email = "" } =
    basicData || {};

  const modalScope = (key, ...args) => {
    return CommonUtils.i18n(`${translationScope}.${key}`, ...args);
  };

  useEffect(() => {
    if (!data.coupon_applied) {
      setPromoCodeStatus("blank");
      setPromoCode(null);
    }
  }, [data.coupon_applied]);

  const renderFreeAccountOption = () => {
    if (basicData) {
      return (
        <div className="create_free_account">
          <Link href={Routes.SIGNUP_PATH}>
            {modalScope("continue_without_subscription")}
          </Link>
        </div>
      );
    }
  };

  const handleAddressChange = (event) => {
    if (event.complete) {
      setSubscriberAddress(event.value.address);
      if (basicData) {
        if (!data.allowed_country.includes(event.value.address.country)) {
          setDisableButton(true);
          Coupa.show_flash_message(
            "error",
            CommonUtils.i18n(
              "coupa_verified.stripe_modal.offering_not_allowed",
              { offering: getHumanizedOfferingName(offering) }
            ) +
              "<a href=" +
              Routes.SIGNUP_PATH +
              ">" +
              CommonUtils.i18n(
                "coupa_verified.stripe_modal.continue_without_subscription"
              ) +
              "</a>",
            false,
            false
          );
        } else {
          setDisableButton(false);
        }
      }
    }
  };

  const toggleSubmitButton = (status) => {
    if (status === "enable") {
      submitRef.current.disabled = false;
      submitRef.current.textContent = modalScope("subscribe");
      submitRef.current.classList.remove("disabled");
    } else {
      submitRef.current.disabled = true;
      submitRef.current.textContent = modalScope("please_wait");
      submitRef.current.classList.add("disabled");
    }
  };

  const handlePaymentSuccess = async () => {
    await dispatch(processPaymentCompleted({ ...data, offering, bundle }));
    if (
      actionType !== null &&
      getErrors("payment") === null &&
      offering === OFFERINGS.COUPA_VERIFIED
    ) {
      nextModal(PAGES.DOC_UPLOAD_FORM);
    } else if (
      actionType !== null &&
      getErrors("payment") === null &&
      offering === OFFERINGS.PREMIUM_SUPPORT
    ) {
      nextModal(PAGES.CALENDLY);
    }
  };

  const handleSetupSuccess = async (payment_method_id) => {
    await dispatch(
      processSetupCompleted({ ...data, offering, bundle, payment_method_id })
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (data?.hundred_percent_promo_applied) {
      await handlePaymentSuccess();
    } else {
      disableForm();
      await dispatch(
        saveSubscriberAddress({
          ...data,
          address: subscriberAddress,
          email: basicData?.invite_email,
        })
      );

      if (trial) {
        await stripe
          .confirmSetup(confirmRequestParams())
          .then(function(result) {
            if (result?.error) {
              enableForm(result?.error);
            } else if (result.setupIntent.status === "succeeded") {
              handleSetupSuccess(result.setupIntent.payment_method);
            }
          });
      } else {
        const {
          error: stripeError,
          paymentIntent,
        } = await stripe.confirmPayment(confirmRequestParams());

        enableForm(stripeError);
        if (paymentIntent?.status === "succeeded") {
          // The payment has succeeded
          await handlePaymentSuccess();
        }
      }
    }
  };

  const disableForm = () => {
    let paymentElement = elements.getElement("payment");
    paymentElement.update({ disabled: true });
    toggleSubmitButton("disable");
    setFormErrors(null);
  };

  const enableForm = (stripeError) => {
    toggleSubmitButton("enable");
    if (stripeError) {
      setFormErrors(stripeError.message);
      dispatch(
        savePaymentErrors(
          { ...data, offering: offering, bundle: bundle },
          stripeError.message
        )
      );
    }
  };

  const confirmRequestParams = () => {
    return {
      elements,
      confirmParams: {
        return_url: data.return_url,
      },
      redirect: "if_required",
    };
  };

  const renderPromoCode = () => {
    if (data.coupon_applied) {
      return renderPromoCodeApplied();
    } else {
      if (promoCodeStatus === "blank") {
        return renderPromoCodeText();
      } else {
        return renderPromoCodeInput();
      }
    }
  };

  const renderPromoCodeText = () => {
    return (
      <span
        className="addPromoCode"
        onClick={() => setPromoCodeStatus("input")}
      >
        {modalScope("add_promo_code")}
      </span>
    );
  };

  const renderPromoCodeInput = () => {
    return (
      <>
        <TextField
          name="promo_code_input"
          inputSelector="promo_code_input"
          value={promoCode}
          requiredField={true}
          showMessages={true}
          errorMessage={data.coupon_error ? data.coupon_error_message : ""}
          onChange={handlePromoCodeChange}
          inputRef={promoCodeRef}
          className={classNames({ error: data.coupon_error })}
        />
        <Button
          buttonText={modalScope("apply")}
          buttonValue={modalScope("apply")}
          className="buttonAsLink"
          onClick={handlePromoCodeApply}
        />
      </>
    );
  };

  const renderPromoCodeApplied = () => {
    return (
      <div className="discountSection">
        <div className="couponDetails">
          <div className="appliedCoupon">
            <Icon className="icon_button sprite-tag_blue couponTag" />
            <span className="couponCode">{data.coupon_applied}</span>
            <img
              src={blankGif}
              alt={CommonUtils.i18n("defaults.remove")}
              onClick={handleRemovePromoCode}
              className="icon icon_button sprite-delete_grey removeCoupon"
            />
          </div>
          {data.percent_off && (
            <div className="percentOff">
              {CommonUtils.i18n("coupa_verified.stripe_modal.percent_off", {
                percent_off: data.percent_off,
              })}
            </div>
          )}
          {data.amount_off && (
            <div className="percentOff">{`$${CommonUtils.i18n(
              "coupa_verified.stripe_modal.amount_off",
              { amount_off: data.amount_off }
            )}`}</div>
          )}
        </div>
        <div className="amountOff">{`-$${data.discounted_amount}`}</div>
      </div>
    );
  };

  const renderTotalDue = () => {
    return data.total_due === undefined
      ? data.subscription_price_usd
      : data.total_due;
  };

  const renderProductLogo = (className) => {
    if (
      !CommonUtils.isEmpty(bundle) ||
      offering === OFFERINGS.PREMIUM_SUPPORT
    ) {
      return <img src={coupaFlower} className={className} />;
    } else if (offering === OFFERINGS.COUPA_ADVANCED) {
      return <img src={coupaAdvancedFlower} className={className} />;
    } else if (offering === OFFERINGS.COUPA_VERIFIED) {
      return <img src={coupaVerifiedLarge} className={className} />;
    }
  };

  const handlePromoCodeChange = (field, value) => {
    setPromoCode(value);
  };

  const handlePromoCodeApply = async () => {
    await dispatch(
      applyPromoCode(data, promoCode, invite_email, offering, bundle)
    );
  };

  const handleRemovePromoCode = () => {
    dispatch(removePromoCode(data, invite_email, offering, bundle));
  };

  const renderInvoiceLines = () =>
    !CommonUtils.isEmpty(data?.invoice_lines) &&
    data.invoice_lines.map(({ offering_name, offering_price }) => {
      return renderInvoiceLine(offering_name, offering_price);
    });

  const renderInvoiceLine = (offering_name, offering_price) => (
    <tr key={offering_name}>
      <td>
        <span>{offering_name}</span>
        <br />
        <span className="footnote">
          {trial
            ? modalScope("billed_yearly_starting", {
                billing_date: data.billing_date,
              })
            : modalScope("billed_yearly")}
        </span>
      </td>
      <td>${offering_price}</td>
    </tr>
  );

  const getOfferingNames = () => {
    if (CommonUtils.isEmpty(data.bundled_subscriptions)) {
      return data.offering_name;
    } else {
      return createLocalizedSentence(data.bundled_subscriptions);
    }
  };

  return (
    <div className="stripeModal flexContainer">
      <div className="coupaInfo">
        <div className="title">
          <div>{renderProductLogo("icon_button icon")}</div>
          <span className="black">{modalScope("coupa_software_inc")}</span>
        </div>
        <div className="note">
          <div className="noteSubscribe -mediumFont">
            {CommonUtils.i18n(
              "coupa_verified.stripe_modal.subscribe_to_offering",
              {
                offering: getOfferingNames(),
              }
            )}
          </div>
          <div className="-smallFont">
            <span className="black notePrice">
              ${data.subscription_price_usd}
            </span>{" "}
            {trial ? modalScope("today") : modalScope("per_year")}
          </div>
          {CommonUtils.isEmpty(data.bundled_subscriptions) && (
            <div className="noteBadge -smallFont">
              {modalScope(`${offering}_tagline`)}
            </div>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="couponCodeBlock">
          <div className="productLogo">{renderProductLogo("fullWidth")}</div>
          <div className="rightPane">
            <table className="couponTable">
              <tbody>
                {renderInvoiceLines()}
                {!trial && (
                  <tr>
                    <td>
                      <span className="">{modalScope("sub_total")}</span>
                    </td>
                    <td>${data.sub_total}</td>
                  </tr>
                )}
              </tbody>
            </table>
            {!trial && <div className="promoCode">{renderPromoCode()}</div>}
            <div className="totalDue">
              <span>{modalScope("total_due")}</span>
              <span className="finalPrice">${renderTotalDue()}</span>
            </div>
          </div>
          <div></div>
        </div>

        <div id="payment-element"></div>
        <div id="payment-request-button-element"></div>
        <br />
        {!data?.hundred_percent_promo_applied && (
          <>
            <AddressElement
              options={{
                mode: "billing",
                defaultValues: {
                  name: (first_name || "") + " " + (last_name || ""),
                },
              }}
              onChange={handleAddressChange}
            />
            <br />
            <PaymentElement />
          </>
        )}
        {formErrors && <div className="center bold errors">{formErrors}</div>}
        <button
          id="submit"
          disabled={basicData && disableButton}
          ref={submitRef}
        >
          {modalScope("subscribe")}
        </button>
        {renderFreeAccountOption()}
      </form>
    </div>
  );
};

export default StripePayment;
