import { AxiosAPIUtils, CommonUtils } from "coupa-common-js";
import { ActionTypes, Routes, Tabs } from "../utils/constants";

function createAccountSuccess(response, signupObj) {
  const data = (response && response.data) || {};
  const tab = data.tab;
  if (tab !== undefined) {
    return {
      type: ActionTypes.SUGGEST_JOIN_ACCOUNT,
      data: {
        signupObj,
        ...data,
        countryList: data.countryList,
      },
    };
  }
  return {
    type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
    data: { ...response },
  };
}

export function createAccountFailed(response, isInvited) {
  const data = (response && response.data) || {};
  const recaptchaFailed = data.recaptchaFailed ? Date.now() : "";
  const tab = data.tab || (isInvited ? Tabs.SIGNUP : Tabs.OTP_VERIFICATION);
  const message =
    (response && response.message) ||
    (isInvited
      ? CommonUtils.i18n("session.signup.error_creating_user")
      : CommonUtils.i18n("session.otp_verification.otp_send_failed"));
  return {
    type: ActionTypes.CREATE_ACCOUNT_FAILED,
    data: {
      message,
      recaptchaFailed,
      tab,
      recaptchaSiteKey: data.recaptchaSiteKey,
    },
  };
}

export function createAccount(signupObj) {
  return (dispatch) => {
    return AxiosAPIUtils.post(Routes.SIGNUP_API, signupObj)
      .then((response) => {
        dispatch(createAccountSuccess(response, signupObj));
      })
      .catch((response) => {
        dispatch(
          createAccountFailed(response.responseJSON, !!signupObj.invite_code)
        );
      });
  };
}
