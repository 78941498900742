import * as AppConstants from "./AppConstants";
import { AxiosAPIUtils, CommonUtils } from "coupa-common-js";

const SupplierCommonUtils = {
  validEmail: function(email) {
    return AppConstants.EMAIL_REGEX.test(email);
  },
  showErrorNotification: function(defaultMessage, responseData) {
    let errMsg = defaultMessage;
    if (responseData && responseData.message) {
      errMsg = responseData.message;
    }
    Coupa.show_flash_message("error", errMsg, false, true, 15000);
  },
  showNotification: function(message) {
    Coupa.show_flash_message("info", message, false, true, 15000);
  },
  getPlaceDetails: function(placeId, data = null) {
    const url = AppConstants.AutoCompAddress.PLACE_DETAILS_API.replace(
      ":placeId",
      placeId
    );
    return AxiosAPIUtils.get(url, { data: data });
  },
  getErrorMessage(response) {
    if (response?.responseJSON?.message) return response?.responseJSON?.message;
    if (response?.status === AppConstants.HTTP_STATUSES.TOO_MANY_REQUESTS) {
      return CommonUtils.i18n("defaults.limit_exceeded");
    }
    return CommonUtils.i18n("defaults.something_wrong_try_again");
  },
};

export default SupplierCommonUtils;
