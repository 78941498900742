import React, { useEffect, useState } from "react";
import classNames from "classnames";

const CustomCheckbox = ({
  id,
  label,
  checked,
  value,
  onChange,
  className,
  ...props
}) => {
  const [allValues, setAllValues] = useState({ isHighlighted: false });

  useEffect(() => {
    if (checked) {
      setAllValues({ ...allValues, isHighlighted: false });
    }
  }, [checked]);

  const toggleHighlight = (_) => {
    if (!checked) {
      setAllValues({ ...allValues, isHighlighted: !allValues.isHighlighted });
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 32) {
      onChange(event);
      event.preventDefault();
    }
  };

  return (
    <div
      className={classNames(
        className,
        "form_element checkbox_element customCheckboxElement",
        {
          "--checked": checked,
        }
      )}
      onClick={onChange}
      data-option={value}
    >
      <div className="inputFieldWrapper">
        <span
          className={classNames(
            "checkMark",
            {
              "--checked": checked,
            },
            allValues.isHighlighted ? "highlightBorder highlightCheckMark" : ""
          )}
        ></span>
        <input
          onChange={onChange}
          type="radio"
          className="customCheckboxElement__checkBox"
          id={id}
          checked={checked}
          value={value}
        />
        <label
          className={"checkboxLabel"}
          htmlFor={id}
          tabIndex="0"
          onBlur={toggleHighlight}
          onFocus={toggleHighlight}
          onKeyDown={onKeyDown}
        >
          {label}
        </label>
      </div>
      {props.children && (
        <div className="checkboxChildren">{props.children}</div>
      )}
    </div>
  );
};

export default CustomCheckbox;
