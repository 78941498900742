let keyMirror = require("keymirror");

module.exports = {
  PHONE_REGEX: /^[()0-9 +-]+$/,
  VALID_EMAIL_REGEX: /^([^:@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i,
  ActionTypes: keyMirror({
    OPEN_MODAL: null,
    CLOSE_MODAL: null,
    OPEN_SUPPLIER_VERIFICATION: null,
    FETCH_STATUS_SUCCESS: null,
    FETCH_STATUS_ERROR: null,
    FETCH_SUPPLIER_INFORMATION_SUCCESS: null,
    FETCH_SUPPLIER_INFORMATION_ERROR: null,
    SUPPLIER_INFORMATION: null,
    SAVE_CONTACT_INFO: null,
    SAVE_CONTACT_INFO_SUCCESS: null,
    SAVE_CONTACT_INFO_ERROR: null,
    CLEAR_ERRORS: null,
    CLEAR_FORM_ERRORS: null,
    SAVE_DOCUMENT_INFO_SUCCESS: null,
    SAVE_DOCUMENT_INFO_ERROR: null,
    SAVE_PAYMENT_INFO: null,
    SAVE_PAYMENT_INFO_SUCCESS: null,
    SAVE_PAYMENT_INFO_ERROR: null,
    ACTIVATE_TRIAL: null,
    ACTIVATE_TRIAL_SUCCESS: null,
    ACTIVATE_TRIAL_ERROR: null,
    SAVE_PAYMENT_ERRORS: null,
    SAVE_PAYMENT_ERRORS_SUCCESS: null,
    SAVE_SUBSCRIBER_ADDRESS_SUCCESS: null,
    CREATE_STRIPE_INTENT_SUCCESS: null,
    CREATE_STRIPE_INTENT_ERROR: null,
    APPLY_PROMO_CODE_SUCCESS: null,
    APPLY_PROMO_CODE_ERROR: null,
    REMOVE_PROMO_CODE_SUCCESS: null,
    REMOVE_PROMO_CODE_ERROR: null,
    CLEAR_PAYMENT_INTENT: null,
    CANCEL_SUBSCRIPTION: null,
    FETCH_COUPA_ADVANCED_INFO: null,
  }),

  ModalTypes: keyMirror({
    SUPPLIER_VERIFICATION: null,
    COUPA_ADVANCED: null,
  }),

  ActionTypesVerification: {
    LOAD_LOGIN: "load_login",
    LOAD_SIGNUP: "load_signup",
    REDIRECT_SIGNUP: "redirect_signup",
  },

  PAGES: {
    CONTACT_INFO_FORM: "form",
    PAYMENT_FORM: "payment",
    DOC_UPLOAD_FORM: "document_upload",
    CALENDLY: "calendly",
    UPGRADE_SUBSCRIPTION: "upgrade_subscription",
  },

  OFFERINGS: {
    COUPA_VERIFIED: "coupa_verified",
    COUPA_ADVANCED: "coupa_advanced",
    PREMIUM_SUPPORT: "premium_support",
    COUPA_ADVANCED: "coupa_advanced",
  },

  MEMBERSHIP: {
    ACTIVE: "Active",
    EXPIRED: "expired",
  },

  ColorCodes: {
    Active: "green",
    Trial: "green",
    Pending: "yellow",
    Expired: "gray",
  },

  CANCELLATION_REASONS: {
    EXPENSIVE: "expensive",
    BENEFITS: "benefits",
    REVISIT: "revisit",
    OTHER: "other",
  },

  STRIPE_INTENT_TYPES: keyMirror({
    PAYMENT_INTENT: null,
    SETUP_INTENT: null,
  }),

  PageAction: keyMirror({
    SINGLE: null,
    CHAINED: null,
  }),

  Routes: {
    FETCH_COUPA_ADVANCED_PLAN:
      "/get_verified/stripe/prepare_upgrade_subscription",
    CONFIRM_COUPA_ADVANCED_UPGRADE:
      "/get_verified/stripe/confirm_upgrade_subscription",
    FETCH_STATUS: "/get_verified/supplier_verification/fetch_status",
    FETCH_VERIFIED_INFO: "/profile/coupa_verified_info",
    FETCH_ADVANCED_INFO: "/profile/coupa_advanced_information",
    FETCH_PREMIUM_SUPPORT_INFO: "/profile/premium_support_information",
    SUPPLIER_INFORMATION:
      "/get_verified/supplier_verification/supplier_information",
    SAVE_CONTACT_INFO: "/get_verified/supplier_verification/save_contact_info",
    SUBSCRIPTION_SUPPLIER_INFORMATION:
      "/get_verified/supplier_subscriptions/supplier_information",
    SUBSCRIPTION_SAVE_CONTACT_INFO:
      "/get_verified/supplier_subscriptions/save_contact_info",
    FETCH_DOCUMENT_DETAILS:
      "/get_verified/supplier_verification/fetch_document_details",
    SAVE_DOCUMENT_INFO:
      "/get_verified/supplier_verification/save_document_info",
    FETCH_STRIPE_KEY: "/get_verified/stripe/fetch_stripe_key",
    CREATE_STRIPE_INTENT: "/get_verified/stripe/create_stripe_intent",
    SAVE_PAYMENT_INFO: "/get_verified/supplier_verification/save_payment_info",
    SAVE_PAYMENT_ERRORS:
      "/get_verified/supplier_verification/save_payment_errors",
    SUBSCRIPTION_ACTIVATE_TRIAL:
      "/get_verified/supplier_subscriptions/activate_trial",
    SUBSCRIPTION_SAVE_PAYMENT_INFO:
      "/get_verified/supplier_subscriptions/save_payment_info",
    SUBSCRIPTION_SAVE_PAYMENT_ERRORS:
      "/get_verified/supplier_subscriptions/save_payment_errors",
    SAVE_SUBSCRIBER_ADDRESS: "/get_verified/stripe/save_subscriber_address",
    CANCEL_SUBSCRIPTION:
      "/get_verified/supplier_verification/cancel_subscription",
    SIGNUP_PATH: "/sessions/new?page=signup",
    CANCEL_SUBSCRIPTION:
      "/get_verified/supplier_verification/cancel_subscription",
    APPLY_PROMO_CODE: "/get_verified/stripe/apply_promo_code",
    REMOVE_PROMO_CODE: "/get_verified/stripe/remove_promo_code",
    PREMIUM_SUPPORT_PATH: "/premium_support",
  },

  Links: {
    MANAGE_PROFILE: "/profile/setup/who-we-are",
    MANAGE_PAYMENT: "/get_verified/stripe/create_customer_portal_session",
    RENEW_SUBSCRIPTION:
      "/get_verified/supplier_verification/renew_subscription",
  },

  // file size in bytes (5MB).
  FILE_SIZE_LIMIT: 5242880,
  ALLOWED_FILE_TYPES: [
    "application/pdf",
    "image/bmp",
    "image/x-ms-bmp",
    "image/jpg",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/x-png",
    "image/tif",
    "image/tiff",
    "image/x-tiff",
  ],
};
