import React, { ReactNode } from "react";
import classNames from "classnames";

import Flex from "supplier-common-ui/dist/components/Flex";
import InfoIcon from "coupa-common-js/core/Icon/InfoIcon";
import Skeleton from "supplier-common-ui/dist/components/Skeleton";

import styles from "./DescriptionItem.module.scss";

const componentId = "DescriptionItem";

export interface DescriptionItemProps {
  id?: string;
  className?: string;
  skeletonClass?: string;
  "data-testid"?: string;
  label: string;
  description?: string;
  children?: ReactNode;
}

const DescriptionItem = ({
  skeletonClass = "mw-300px",
  ...props
}: DescriptionItemProps) => {
  const classes = classNames(componentId, props.className);

  return (
    <Skeleton
      id={props.id}
      className={classNames(classes, skeletonClass)}
      data-testid={props["data-testid"]}
    >
      <Flex
        id={props.id}
        className={classes}
        data-testid={props["data-testid"]}
        gap="3"
      >
        <dt className={styles.dt}>
          <span className="label label-default">{props.label}</span>
          {props.description && (
            <InfoIcon className={styles.info}>{props.description}</InfoIcon>
          )}
        </dt>
        {props.children && (
          <dd className={styles.children}>{props.children}</dd>
        )}
      </Flex>
    </Skeleton>
  );
};

export default DescriptionItem;
